<template>
  <div class="reports">
    <v-container class="mt-10 mb-3">
      <v-row>
        <v-col sm="12" md="6" lg="4">
          <smoking-report @genSmokingRpt="genSmokingRpt"></smoking-report>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mx-3" inset color="green"></v-divider>
    <v-container>
      <smoking-report-table
        v-if="showSmokingTable"
        :generate="generate"
        :generated="generated"
        :alerts="alerts"
      ></smoking-report-table>
    </v-container>
  </div>
</template>

<script>
import SmokingReport from '../components/reports/SmokingReport'
import SmokingReportTable from '../components/reports/SmokingReportTable'
import { getAlerts, getRides } from '../helpers/reports'
export default {
  name: 'reports',
  components: {
    'smoking-report': SmokingReport,
    'smoking-report-table': SmokingReportTable,
  },
  data() {
    return {
      generate: false,
      generated: true,
      alerts: [],
      rides: [],
      showSmokingTable: true,
      showRidesTable: false,
    }
  },
  computed: {},
  methods: {
    async genSmokingRpt() {
      alert('Demo mode')
    },
    async genRidesRpt() {
      // alert(`generating report for time range: ${this.startDate} - ${this.endDate}`);
      alert('Demo mode')
    },
  },
}
</script>

<style scoped>
.premium {
  filter: blur(5px);
}
</style>