import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import App from '@/App.vue'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { Auth } from 'aws-amplify'
import { AmplifyPlugin, AmplifyEventBus } from 'aws-amplify-vue'
import VueNativeSock from 'vue-native-websocket'
import awsconfig from './aws-exports'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import store from '@/store/store'
import '@/vee-validate.js'
import { WS_URL } from '@/configs/wsConfig'

Amplify.configure(awsconfig)
Vue.use(Auth)
Vue.use(AmplifyPlugin, AmplifyModules)
Amplify.Logger.LOG_LEVEL = 'INFO'

Vue.prototype.$STAGE = process.env.VUE_APP_STAGE
console.log(` -- App stage: ${process.env.VUE_APP_STAGE} --`)
console.log(process.env)

Vue.use(VueNativeSock, WS_URL, {
  store: store,
  format: 'json',
  connectManually: true,
  reconnection: true,
  passToStoreHandler: function(eventName, event) {
    console.log(`[WS passToStoreHandler] eventName: ${eventName} event: ${JSON.stringify(event)}`)
    if (!eventName.includes('SOCKET_')) {
      return
    }
    let method = 'commit'
    let target = 'websocket/' + eventName.toUpperCase()
    let msg = event
    // console.log(`[WS passToStoreHandler] msg: ${JSON.stringify(msg)}`)
    if (this.format === 'json' && event.data) {
      // console.log('parsing event.data', event.data)
      msg = JSON.parse(event.data)
      if (msg.mutation) {
        target = [msg.namespace || '', msg.mutation].filter(e => !!e).join('/')
      } else if (msg.action) {
        method = 'dispatch'
        target = [msg.namespace || '', msg.action].filter(e => !!e).join('/')
      }
    }
    // console.log(`method: ${method}, target: ${target}, msg:${JSON.stringify(msg)}`)
    store[method](target, msg)
  }
})

Vue.config.productionTip = false

const vm = new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  methods: {}
}).$mount('#app')

AmplifyEventBus.$on('authState', info => {
  console.log('AmplifyEventBus on AuthState change', info)
  if (info === 'signedIn') {
    // store.dispatch('fetchInitialData')
    // console.log('connecting to websocket')
    // vm.$connect()
    router.push('/')
  }
  if (info === 'signedOut') {
    // vm.$disconnect()
    store.dispatch('auth/resetAuthState')
    store.reset()
    router.push('/login')
  }
})
