<template>
  <div>
    <v-container>
      <v-row justify="start" align-content="space-between">
        <v-col cols="3">
          <pollutant-card
            pollutantName="PM10"
            pollutantValue="60"
            title="Dust Score"
            category="Poor"
          ></pollutant-card>
        </v-col>
        <v-col cols="3">
          <pollutant-card
            pollutantName="PM2.5"
            pollutantValue="65"
            title="Fine Particles Score"
            category="Good"
          ></pollutant-card>
        </v-col>
        <v-col cols="3">
          <pollutant-card
            pollutantName="Chemicals"
            pollutantValue="52"
            title="Chemicals Score"
            category="Poor"
          ></pollutant-card>
        </v-col>
        <v-col cols="3">
          <pollutant-card
            pollutantName="CO2"
            pollutantValue="58"
            title="CO2 Score"
            category="Good"
          ></pollutant-card
        ></v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col cols="2">
          <v-text-field
            hide-details="auto"
            clearable
            dense
            label="Filter by vehicle ID"
            append-icon="search"
            v-model="deviceFilter"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <device-card
            v-for="device in filteredDevices"
            :key="device.device"
            class="pb-3"
            :data="device"
          ></device-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import pollutantCard from '../components/crisp/pollutantCard.vue'
import deviceCard from '../components/crisp/deviceCard.vue'
export default {
  name: 'crisp-view',
  components: {
    'pollutant-card': pollutantCard,
    'device-card': deviceCard,
  },
  data() {
    return {
      deviceFilter: null,
      devicesData: [
        {
          device: 72335686,
          pm10: 191,
          pm25: 137,
          voc: 59,
          co2: 590,
          temp: 20,
          humidity: 38,
        },
        {
          device: 36718224,
          pm10: 21,
          pm25: 42,
          voc: 98,
          co2: 540,
          temp: 27,
          humidity: 30,
        },
        {
          device: 21838439,
          pm10: 179,
          pm25: 135,
          voc: 12,
          co2: 717,
          temp: 24,
          humidity: 30,
        },
        {
          device: 38450760,
          pm10: 92,
          pm25: 120,
          voc: 28,
          co2: 709,
          temp: 19,
          humidity: 50,
        },
        {
          device: 26497781,
          pm10: 181,
          pm25: 193,
          voc: 83,
          co2: 668,
          temp: 29,
          humidity: 31,
        },
        {
          device: 60624049,
          pm10: 28,
          pm25: 40,
          voc: 30,
          co2: 427,
          temp: 18,
          humidity: 38,
        },
        {
          device: 90634226,
          pm10: 13,
          pm25: 103,
          voc: 52,
          co2: 531,
          temp: 18,
          humidity: 30,
        },
        {
          device: 40981158,
          pm10: 166,
          pm25: 55,
          voc: 14,
          co2: 769,
          temp: 19,
          humidity: 37,
        },
        {
          device: 91460475,
          pm10: 71,
          pm25: 24,
          voc: 6,
          co2: 782,
          temp: 26,
          humidity: 45,
        },
        {
          device: 57299345,
          pm10: 75,
          pm25: 117,
          voc: 93,
          co2: 502,
          temp: 20,
          humidity: 40,
        },
        {
          device: 88686877,
          pm10: 166,
          pm25: 184,
          voc: 80,
          co2: 634,
          temp: 25,
          humidity: 48,
        },
        {
          device: 62552733,
          pm10: 193,
          pm25: 0,
          voc: 88,
          co2: 771,
          temp: 28,
          humidity: 50,
        },
        {
          device: 66748724,
          pm10: 180,
          pm25: 22,
          voc: 43,
          co2: 783,
          temp: 26,
          humidity: 33,
        },
        {
          device: 15187257,
          pm10: 178,
          pm25: 73,
          voc: 77,
          co2: 646,
          temp: 30,
          humidity: 36,
        },
        {
          device: 40948002,
          pm10: 26,
          pm25: 120,
          voc: 98,
          co2: 452,
          temp: 19,
          humidity: 37,
        },
        {
          device: 60997695,
          pm10: 107,
          pm25: 88,
          voc: 61,
          co2: 713,
          temp: 29,
          humidity: 49,
        },
        {
          device: 47980652,
          pm10: 55,
          pm25: 113,
          voc: 78,
          co2: 549,
          temp: 20,
          humidity: 32,
        },
        {
          device: 76711748,
          pm10: 85,
          pm25: 133,
          voc: 88,
          co2: 685,
          temp: 28,
          humidity: 36,
        },
        {
          device: 44191650,
          pm10: 51,
          pm25: 131,
          voc: 16,
          co2: 535,
          temp: 22,
          humidity: 30,
        },
        {
          device: 49722593,
          pm10: 99,
          pm25: 31,
          voc: 73,
          co2: 423,
          temp: 20,
          humidity: 34,
        },
        {
          device: 21531353,
          pm10: 112,
          pm25: 23,
          voc: 12,
          co2: 475,
          temp: 18,
          humidity: 40,
        },
        {
          device: 62196700,
          pm10: 130,
          pm25: 69,
          voc: 2,
          co2: 407,
          temp: 20,
          humidity: 37,
        },
        {
          device: 47135731,
          pm10: 154,
          pm25: 20,
          voc: 42,
          co2: 753,
          temp: 23,
          humidity: 48,
        },
        {
          device: 21447760,
          pm10: 116,
          pm25: 100,
          voc: 38,
          co2: 723,
          temp: 23,
          humidity: 50,
        },
        {
          device: 51244230,
          pm10: 22,
          pm25: 139,
          voc: 98,
          co2: 550,
          temp: 30,
          humidity: 46,
        },
        {
          device: 74225918,
          pm10: 72,
          pm25: 26,
          voc: 76,
          co2: 715,
          temp: 21,
          humidity: 38,
        },
        {
          device: 76480769,
          pm10: 93,
          pm25: 183,
          voc: 72,
          co2: 719,
          temp: 28,
          humidity: 43,
        },
        {
          device: 94274100,
          pm10: 158,
          pm25: 93,
          voc: 55,
          co2: 693,
          temp: 19,
          humidity: 45,
        },
        {
          device: 21163586,
          pm10: 186,
          pm25: 25,
          voc: 52,
          co2: 550,
          temp: 21,
          humidity: 36,
        },
        {
          device: 79223757,
          pm10: 72,
          pm25: 102,
          voc: 80,
          co2: 538,
          temp: 23,
          humidity: 45,
        },
        {
          device: 52544633,
          pm10: 61,
          pm25: 157,
          voc: 68,
          co2: 463,
          temp: 21,
          humidity: 30,
        },
        {
          device: 64378180,
          pm10: 169,
          pm25: 102,
          voc: 30,
          co2: 628,
          temp: 23,
          humidity: 47,
        },
        {
          device: 47760286,
          pm10: 174,
          pm25: 64,
          voc: 50,
          co2: 500,
          temp: 18,
          humidity: 46,
        },
        {
          device: 92953409,
          pm10: 200,
          pm25: 100,
          voc: 100,
          co2: 549,
          temp: 26,
          humidity: 42,
        },
        {
          device: 60529885,
          pm10: 62,
          pm25: 19,
          voc: 13,
          co2: 451,
          temp: 25,
          humidity: 48,
        },
        {
          device: 81239768,
          pm10: 92,
          pm25: 96,
          voc: 67,
          co2: 612,
          temp: 19,
          humidity: 30,
        },
        {
          device: 10938354,
          pm10: 48,
          pm25: 60,
          voc: 12,
          co2: 650,
          temp: 23,
          humidity: 33,
        },
        {
          device: 86814803,
          pm10: 14,
          pm25: 170,
          voc: 87,
          co2: 677,
          temp: 25,
          humidity: 50,
        },
        {
          device: 14823302,
          pm10: 13,
          pm25: 180,
          voc: 82,
          co2: 627,
          temp: 19,
          humidity: 43,
        },
        {
          device: 23789596,
          pm10: 181,
          pm25: 51,
          voc: 5,
          co2: 757,
          temp: 21,
          humidity: 36,
        },
        {
          device: 53918537,
          pm10: 88,
          pm25: 61,
          voc: 89,
          co2: 757,
          temp: 24,
          humidity: 43,
        },
        {
          device: 75982871,
          pm10: 22,
          pm25: 53,
          voc: 94,
          co2: 475,
          temp: 23,
          humidity: 39,
        },
        {
          device: 41724129,
          pm10: 50,
          pm25: 85,
          voc: 2,
          co2: 473,
          temp: 23,
          humidity: 32,
        },
        {
          device: 94764634,
          pm10: 18,
          pm25: 121,
          voc: 82,
          co2: 518,
          temp: 29,
          humidity: 48,
        },
        {
          device: 36516719,
          pm10: 96,
          pm25: 57,
          voc: 30,
          co2: 768,
          temp: 22,
          humidity: 44,
        },
      ],
    }
  },
  computed: {
    filteredDevices: function () {
      let filter
      if (this.deviceFilter === null) filter = '\\d+'
      else filter = this.deviceFilter
      return this.devicesData.filter((d) => {
        const re = new RegExp(filter)
        return d.device.toString().match(re)
      })
    },
  },
}
</script>

<style>
</style>