import axios from 'axios'
import { getWeekAgoTimestamp, getMonthAgoTimestamp } from '../../helpers/utils.js'
import { getAlerts, fetchSmokingRank } from '../../helpers/reports.js'
import { eventsByCar } from '../../helpers/insights.js'
import { restConfig } from '@/configs/restConfig'

export default {
  namespaced: true,
  state: {
    TotalCrispifiedRides: 0,
    Top10WorstRidesRange1: [],
    Top10WorstRidesRange7: [],
    Top10WorstRidesRange1fetched: false,
    Top10WorstRidesRange7fetched: false,
    SmokingEventsLastWeek: [
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-22T04:24:44.000Z',
        event_end: 1616387084,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616387036,
        event_start_time: '2021-03-22T04:23:56.000Z',
        date: 'Mar-22-2021 6:23:56'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-21T22:31:07.000Z',
        event_end: 1616365867,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616365841,
        event_start_time: '2021-03-21T22:30:41.000Z',
        date: 'Mar-22-2021 0:30:41'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-21T22:29:59.000Z',
        event_end: 1616365799,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616365781,
        event_start_time: '2021-03-21T22:29:41.000Z',
        date: 'Mar-22-2021 0:29:41'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-18T18:54:10.000Z',
        event_end: 1616093650,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616093457,
        event_start_time: '2021-03-18T18:50:57.000Z',
        date: 'Mar-18-2021 20:50:57'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-18T18:38:24.000Z',
        event_end: 1616092704,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616092410,
        event_start_time: '2021-03-18T18:33:30.000Z',
        date: 'Mar-18-2021 20:33:30'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-16T04:49:21.000Z',
        event_end: 1615870161,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615869908,
        event_start_time: '2021-03-16T04:45:08.000Z',
        date: 'Mar-16-2021 6:45:08'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 13,
        event_end_time: '2021-03-16T03:21:45.000Z',
        event_end: 1615864905,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615864585,
        event_start_time: '2021-03-16T03:16:25.000Z',
        date: 'Mar-16-2021 5:16:25'
      }
    ],
    SmokingEventsLastMonth: [
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-22T04:24:44.000Z',
        event_end: 1616387084,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616387036,
        event_start_time: '2021-03-22T04:23:56.000Z',
        date: 'Mar-22-2021 6:23:56'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-21T22:31:07.000Z',
        event_end: 1616365867,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616365841,
        event_start_time: '2021-03-21T22:30:41.000Z',
        date: 'Mar-22-2021 0:30:41'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-21T22:29:59.000Z',
        event_end: 1616365799,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616365781,
        event_start_time: '2021-03-21T22:29:41.000Z',
        date: 'Mar-22-2021 0:29:41'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-18T18:54:10.000Z',
        event_end: 1616093650,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616093457,
        event_start_time: '2021-03-18T18:50:57.000Z',
        date: 'Mar-18-2021 20:50:57'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-18T18:38:24.000Z',
        event_end: 1616092704,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616092410,
        event_start_time: '2021-03-18T18:33:30.000Z',
        date: 'Mar-18-2021 20:33:30'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-16T04:49:21.000Z',
        event_end: 1615870161,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615869908,
        event_start_time: '2021-03-16T04:45:08.000Z',
        date: 'Mar-16-2021 6:45:08'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 13,
        event_end_time: '2021-03-16T03:21:45.000Z',
        event_end: 1615864905,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615864585,
        event_start_time: '2021-03-16T03:16:25.000Z',
        date: 'Mar-16-2021 5:16:25'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-15T05:23:03.000Z',
        event_end: 1615785783,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615785203,
        event_start_time: '2021-03-15T05:13:23.000Z',
        date: 'Mar-15-2021 7:13:23'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-15T02:05:03.000Z',
        event_end: 1615773903,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615773575,
        event_start_time: '2021-03-15T01:59:35.000Z',
        date: 'Mar-15-2021 3:59:35'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-03-12T23:26:04.000Z',
        event_end: 1615591564,
        vin: '94739746',
        device_id: 'NS20470028',
        event_start: 1615591366,
        event_start_time: '2021-03-12T23:22:46.000Z',
        date: 'Mar-13-2021 1:22:46'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-12T23:11:10.000Z',
        event_end: 1615590670,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615590592,
        event_start_time: '2021-03-12T23:09:52.000Z',
        date: 'Mar-13-2021 1:09:52'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-12T23:05:02.000Z',
        event_end: 1615590302,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615589893,
        event_start_time: '2021-03-12T22:58:13.000Z',
        date: 'Mar-13-2021 0:58:13'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-12T19:09:34.000Z',
        event_end: 1615576174,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615575900,
        event_start_time: '2021-03-12T19:05:00.000Z',
        date: 'Mar-12-2021 21:05:00'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 13,
        event_end_time: '2021-03-11T15:59:42.000Z',
        event_end: 1615478382,
        vin: '94739746',
        device_id: 'NS20470028',
        event_start: 1615477873,
        event_start_time: '2021-03-11T15:51:13.000Z',
        date: 'Mar-11-2021 17:51:13'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-11T00:14:26.000Z',
        event_end: 1615421666,
        vin: '94739746',
        device_id: 'NS20470028',
        event_start: 1615421151,
        event_start_time: '2021-03-11T00:05:51.000Z',
        date: 'Mar-11-2021 2:05:51'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-10T16:47:57.000Z',
        event_end: 1615394877,
        vin: '90614182',
        device_id: 'NS20470047',
        event_start: 1615392755,
        event_start_time: '2021-03-10T16:12:35.000Z',
        date: 'Mar-10-2021 18:12:35'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-03-10T16:06:44.000Z',
        event_end: 1615392404,
        vin: '90614182',
        device_id: 'NS20470047',
        event_start: 1615392052,
        event_start_time: '2021-03-10T16:00:52.000Z',
        date: 'Mar-10-2021 18:00:52'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 13,
        event_end_time: '2021-03-10T00:38:47.000Z',
        event_end: 1615336727,
        vin: '94676971',
        device_id: 'NS20470006',
        event_start: 1615336226,
        event_start_time: '2021-03-10T00:30:26.000Z',
        date: 'Mar-10-2021 2:30:26'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-09T20:00:03.000Z',
        event_end: 1615320003,
        vin: '94676971',
        device_id: 'NS20470006',
        event_start: 1615319901,
        event_start_time: '2021-03-09T19:58:21.000Z',
        date: 'Mar-9-2021 21:58:21'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-09T19:47:04.000Z',
        event_end: 1615319224,
        vin: '94676971',
        device_id: 'NS20470006',
        event_start: 1615318315,
        event_start_time: '2021-03-09T19:31:55.000Z',
        date: 'Mar-9-2021 21:31:55'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-09T19:21:29.000Z',
        event_end: 1615317689,
        vin: '94676971',
        device_id: 'NS20470006',
        event_start: 1615315703,
        event_start_time: '2021-03-09T18:48:23.000Z',
        date: 'Mar-9-2021 20:48:23'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-09T16:38:42.000Z',
        event_end: 1615307922,
        vin: '94676971',
        device_id: 'NS20470006',
        event_start: 1615307448,
        event_start_time: '2021-03-09T16:30:48.000Z',
        date: 'Mar-9-2021 18:30:48'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-09T16:05:20.000Z\t',
        event_end: 1615305920,
        vin: '94676971',
        device_id: 'NS20470006',
        event_start: 1615305113,
        event_start_time: '2021-03-09T15:51:53.000Z',
        date: 'Mar-9-2021 17:51:53'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-08T17:12:14.000Z',
        event_end: 1615223534,
        vin: '94739746',
        device_id: 'NS20470028',
        event_start: 1615223368,
        event_start_time: '2021-03-08T17:09:28.000Z',
        date: 'Mar-8-2021 19:09:28'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-06T22:29:43.000Z',
        event_end: 1615069783,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615069621,
        event_start_time: '2021-03-06T22:27:01.000Z',
        date: 'Mar-7-2021 0:27:01'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-06T16:12:50.000Z',
        event_end: 1615047170,
        vin: '90614182',
        device_id: 'NS20470047',
        event_start: 1615047031,
        event_start_time: '2021-03-06T16:10:31.000Z',
        date: 'Mar-6-2021 18:10:31'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-02T01:31:34.000Z',
        event_end: 1614648694,
        vin: '94739746',
        device_id: 'NS20470028',
        event_start: 1614648684,
        event_start_time: '2021-03-02T01:31:24.000Z',
        date: 'Mar-2-2021 3:31:24'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-28T19:05:29.000Z',
        event_end: 1614539129,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1614539111,
        event_start_time: '2021-02-28T19:05:11.000Z',
        date: 'Feb-28-2021 21:05:11'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-28T15:33:02.000Z',
        event_end: 1614526382,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1614526334,
        event_start_time: '2021-02-28T15:32:14.000Z',
        date: 'Feb-28-2021 17:32:14'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-27T20:16:02.000Z',
        event_end: 1614456962,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1614456822,
        event_start_time: '2021-02-27T20:13:42.000Z',
        date: 'Feb-27-2021 22:13:42'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-27T20:14:10.000Z',
        event_end: 1614456850,
        vin: '90614182',
        device_id: 'NS20470047',
        event_start: 1614456822,
        event_start_time: '2021-02-27T20:13:42.000Z',
        date: 'Feb-27-2021 22:13:42'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T20:07:19.000Z',
        event_end: 1614024439,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1614024373,
        event_start_time: '2021-02-22T20:06:13.000Z',
        date: 'Feb-22-2021 22:06:13'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T19:15:15.000Z',
        event_end: 1614021315,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1614021065,
        event_start_time: '2021-02-22T19:11:05.000Z',
        date: 'Feb-22-2021 21:11:05'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T11:59:46.000Z',
        event_end: 1613995186,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613994942,
        event_start_time: '2021-02-22T11:55:42.000Z',
        date: 'Feb-22-2021 13:55:42'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 13,
        event_end_time: '2021-02-22T11:07:18.000Z',
        event_end: 1613992038,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613992022,
        event_start_time: '2021-02-22T11:07:02.000Z',
        date: 'Feb-22-2021 13:07:02'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T06:58:09.000Z',
        event_end: 1613977089,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613976722,
        event_start_time: '2021-02-22T06:52:02.000Z',
        date: 'Feb-22-2021 8:52:02'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T06:51:42.000Z',
        event_end: 1613976702,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613976682,
        event_start_time: '2021-02-22T06:51:22.000Z',
        date: 'Feb-22-2021 8:51:22'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T06:43:30.000Z',
        event_end: 1613976210,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613975378,
        event_start_time: '2021-02-22T06:29:38.000Z',
        date: 'Feb-22-2021 8:29:38'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T06:10:06.000Z',
        event_end: 1613974206,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613973702,
        event_start_time: '2021-02-22T06:01:42.000Z',
        date: 'Feb-22-2021 8:01:42'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T05:57:46.000Z',
        event_end: 1613973466,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613973438,
        event_start_time: '2021-02-22T05:57:18.000Z',
        date: 'Feb-22-2021 7:57:18'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T05:52:28.000Z',
        event_end: 1613973148,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613972888,
        event_start_time: '2021-02-22T05:48:08.000Z',
        date: 'Feb-22-2021 7:48:08'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T05:48:04.000Z',
        event_end: 1613972884,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613972589,
        event_start_time: '2021-02-22T05:43:09.000Z',
        date: 'Feb-22-2021 7:43:09'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T05:33:55.000Z',
        event_end: 1613972035,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613971561,
        event_start_time: '2021-02-22T05:26:01.000Z',
        date: 'Feb-22-2021 7:26:01'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T05:24:04.000Z',
        event_end: 1613971444,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613971181,
        event_start_time: '2021-02-22T05:19:41.000Z',
        date: 'Feb-22-2021 7:19:41'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-02-22T05:06:34.000Z',
        event_end: 1613970394,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613969770,
        event_start_time: '2021-02-22T04:56:10.000Z',
        date: 'Feb-22-2021 6:56:10'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T03:44:31.000Z',
        event_end: 1613965471,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613965157,
        event_start_time: '2021-02-22T03:39:17.000Z',
        date: 'Feb-22-2021 5:39:17'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T03:34:09.000Z',
        event_end: 1613964849,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613964523,
        event_start_time: '2021-02-22T03:28:43.000Z',
        date: 'Feb-22-2021 5:28:43'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T03:19:03.000Z',
        event_end: 1613963943,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613963923,
        event_start_time: '2021-02-22T03:18:43.000Z',
        date: 'Feb-22-2021 5:18:43'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T02:55:58.000Z',
        event_end: 1613962558,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613962516,
        event_start_time: '2021-02-22T02:55:16.000Z',
        date: 'Feb-22-2021 4:55:16'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-22T02:51:28.000Z',
        event_end: 1613962288,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613961592,
        event_start_time: '2021-02-22T02:39:52.000Z',
        date: 'Feb-22-2021 4:39:52'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-02-22T02:18:03.000Z',
        event_end: 1613960283,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613960257,
        event_start_time: '2021-02-22T02:17:37.000Z',
        date: 'Feb-22-2021 4:17:37'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T02:10:55.000Z',
        event_end: 1613959855,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613959611,
        event_start_time: '2021-02-22T02:06:51.000Z',
        date: 'Feb-22-2021 4:06:51'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-02-22T02:05:41.000Z',
        event_end: 1613959541,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613959295,
        event_start_time: '2021-02-22T02:01:35.000Z',
        date: 'Feb-22-2021 4:01:35'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-02-21T23:55:18.000Z',
        event_end: 1613951718,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613951659,
        event_start_time: '2021-02-21T23:54:19.000Z',
        date: 'Feb-22-2021 1:54:19'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-02-21T22:14:38.000Z',
        event_end: 1613945678,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613945646,
        event_start_time: '2021-02-21T22:14:06.000Z',
        date: 'Feb-22-2021 0:14:06'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 13,
        event_end_time: '2021-02-21T21:58:39.000Z',
        event_end: 1613944719,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1613944669,
        event_start_time: '2021-02-21T21:57:49.000Z',
        date: 'Feb-21-2021 23:57:49'
      }
    ],
    LatestSmokingEvents: [
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-22T04:24:44.000Z',
        event_end: 1616387084,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616387036,
        event_start_time: '2021-03-22T04:23:56.000Z',
        date: 'Mar-22-2021 6:23:56'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-21T22:31:07.000Z',
        event_end: 1616365867,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616365841,
        event_start_time: '2021-03-21T22:30:41.000Z',
        date: 'Mar-22-2021 0:30:41'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-21T22:29:59.000Z',
        event_end: 1616365799,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616365781,
        event_start_time: '2021-03-21T22:29:41.000Z',
        date: 'Mar-22-2021 0:29:41'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 11,
        event_end_time: '2021-03-18T18:54:10.000Z',
        event_end: 1616093650,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616093457,
        event_start_time: '2021-03-18T18:50:57.000Z',
        date: 'Mar-18-2021 20:50:57'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-18T18:38:24.000Z',
        event_end: 1616092704,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1616092410,
        event_start_time: '2021-03-18T18:33:30.000Z',
        date: 'Mar-18-2021 20:33:30'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-16T04:49:21.000Z',
        event_end: 1615870161,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615869908,
        event_start_time: '2021-03-16T04:45:08.000Z',
        date: 'Mar-16-2021 6:45:08'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 13,
        event_end_time: '2021-03-16T03:21:45.000Z',
        event_end: 1615864905,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615864585,
        event_start_time: '2021-03-16T03:16:25.000Z',
        date: 'Mar-16-2021 5:16:25'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-15T05:23:03.000Z',
        event_end: 1615785783,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615785203,
        event_start_time: '2021-03-15T05:13:23.000Z',
        date: 'Mar-15-2021 7:13:23'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 12,
        event_end_time: '2021-03-15T02:05:03.000Z',
        event_end: 1615773903,
        vin: '91142450',
        device_id: 'NS20470002',
        event_start: 1615773575,
        event_start_time: '2021-03-15T01:59:35.000Z',
        date: 'Mar-15-2021 3:59:35'
      },
      {
        org: '01',
        event_type: 'smoking',
        smoking_score: 15,
        event_end_time: '2021-03-12T23:26:04.000Z',
        event_end: 1615591564,
        vin: '94739746',
        device_id: 'NS20470028',
        event_start: 1615591366,
        event_start_time: '2021-03-12T23:22:46.000Z',
        date: 'Mar-13-2021 1:22:46'
      }
    ],
    SmokingEventsLastWeekFethced: false,
    SmokingEventsLastMonthFetched: false,
    SmokingEventsRankAll: [
      {
        vin: '91142450',
        org: '01',
        deviceId: 'NS20470002',
        smoking_events_count: 77
      },
      {
        vin: '94676971',
        org: '01',
        deviceId: 'NS20470006',
        smoking_events_count: 52
      },
      {
        vin: '94739746',
        org: '01',
        deviceId: 'NS20470028',
        smoking_events_count: 23
      },
      {
        vin: '90614182',
        org: '01',
        deviceId: 'NS20470047',
        smoking_events_count: 11
      }
    ],
    SmokingEventsRankMonth: [
      {
        deviceId: 'NS20470002',
        vin: '91142450',
        org: '01',
        smoking_events_count: 14
      },
      {
        deviceId: 'NS20470028',
        vin: '94739746',
        org: '01',
        smoking_events_count: 10
      },
      {
        deviceId: 'NS20470047',
        vin: '90614182',
        org: '01',
        smoking_events_count: 5
      },
      {
        deviceId: 'NS20470006',
        vin: '94676971',
        org: '01',
        smoking_events_count: 2
      }
    ],
    SmokingEventsRankWeek: [
      {
        deviceId: 'NS20470002',
        vin: '91142450',
        org: '01',
        smoking_events_count: 5
      },
      {
        deviceId: 'NS20470028',
        vin: '94739746',
        org: '01',
        smoking_events_count: 2
      },
      {
        deviceId: 'NS20470047',
        vin: '90614182',
        org: '01',
        smoking_events_count: 1
      },
      {
        deviceId: 'NS20470006',
        vin: '94676971',
        org: '01',
        smoking_events_count: 0
      }
    ]
  },
  getters: {
    getTotalCrispifiedRides: state => {
      return state.TotalCrispifiedRides
    },
    getTop10WorstRidesRange1: state => {
      return state.Top10WorstRidesRange1
    },
    getTop10WorstRidesRange7: state => {
      return state.Top10WorstRidesRange7
    },
    getTop10WorstRidesRange1fetched: state => {
      return state.Top10WorstRidesRange1fetched
    },
    getTop10WorstRidesRange7fetched: state => {
      return state.Top10WorstRidesRange7fetched
    },
    getSmokingEventsLastWeek: state => {
      return state.SmokingEventsLastWeek
    },
    getSmokingEventsLastMonth: state => {
      return state.SmokingEventsLastMonth
    },
    getSmokingEventsLastWeekFethced: state => {
      return state.SmokingEventsLastWeekFethced
    },
    getSmokingEventsLastMonthFetched: state => {
      return state.SmokingEventsLastMonthFetched
    },
    getLatestSmokingEvents: state => {
      return state.LatestSmokingEvents
    },
    getSmokingEventsRankAll: state => {
      return state.SmokingEventsRankAll
    },
    getSmokingEventsRankMonth: state => {
      return state.SmokingEventsRankMonth
    },
    getSmokingEventsRankWeek: state => {
      return state.SmokingEventsRankWeek
    }
  },
  mutations: {
    setTotalCrispifiedRides(state, sum) {
      state.TotalCrispifiedRides = sum
    },
    setTop10WorstRidesRange1(state, payload) {
      state.Top10WorstRidesRange1 = payload
    },
    setTop10WorstRidesRange7(state, payload) {
      state.Top10WorstRidesRange7 = payload
    },
    setTop10WorstRidesRange1fetched(state, payload) {
      state.Top10WorstRidesRange1fetched = payload.flag
    },
    setTop10WorstRidesRange7fetched(state, payload) {
      state.Top10WorstRidesRange7fetched = payload.flag
    },
    setSmokingEventsLastWeek(state, payload) {
      state.SmokingEventsLastWeek = payload
    },
    setSmokingEventsLastMonth(state, payload) {
      state.SmokingEventsLastMonth = payload
    },
    setSmokingEventsLastWeekFethced(state, payload) {
      state.SmokingEventsLastWeekFethced = payload.flag
    },
    setSmokingEventsLastMonthFetched(state, payload) {
      state.SmokingEventsLastMonthFetched = payload.flag
    },
    setLatestSmokingEvents(state, payload) {
      const latestEvents = payload.slice(0, 10)
      state.LatestSmokingEvents = latestEvents
    },
    setSmokingRankAll(state, payload) {
      state.SmokingEventsRankAll = payload
    },
    setSmokingRankMonth(state, payload) {
      state.SmokingEventsRankMonth = payload
    },
    setSmokingRankWeek(state, payload) {
      state.SmokingEventsRankWeek = payload
    }
  },

  actions: {
    async getTop10WorstRides(context, payload) {
      console.log('getTop10WorstRides action invoked with ' + JSON.stringify(payload))
      const range = payload.range
      const end = Math.floor(Date.now() / 1000)
      const jwt = context.rootGetters['auth/getUserJwt']
      const config = {
        headers: {
          Authorization: jwt
        }
      }
      const qStr = `${restConfig.aggreagtionsEndpoint}/rides/aqi_rank?end=${end}&range=${range}`
      await axios
        .get(qStr, config)
        .then(res => {
          // console.log(res)
          if (payload.range === 1) {
            context.commit('setTop10WorstRidesRange1', res.data.splice(0, 10))
            context.commit('setTop10WorstRidesRange1fetched', { flag: true })
          } else {
            context.commit('setTop10WorstRidesRange7', res.data.splice(0, 10))
            context.commit('setTop10WorstRidesRange7fetched', { flag: true })
          }
        })
        .catch(err => {
          console.error('getTop10WorstRides api err: ' + err)
        })
    },

    async getSmokingEventsForTrend(context) {
      // console.log(`getSmokingEvents action invoked`)
      const end = Math.floor(new Date() / 1000)
      const jwt = context.rootGetters['auth/getUserJwt']

      const start = getMonthAgoTimestamp(end)
      const smokingEventsLastMonth = await getAlerts(jwt, start, end)
      const smokingEventsLatest = smokingEventsLastMonth.sort((a, b) => {
        return b.event_start - a.event_start
      })
      const smokingEventsLastWeek = smokingEventsLastMonth.filter(
        event => event.event_start >= getWeekAgoTimestamp(end)
      )
      context.commit('setSmokingEventsLastMonth', smokingEventsLastMonth)
      context.commit('setSmokingEventsLastWeek', smokingEventsLastWeek)
      context.commit('setLatestSmokingEvents', smokingEventsLatest)

      context.commit('setSmokingRankMonth', eventsByCar(smokingEventsLastMonth))
      context.commit('setSmokingRankWeek', eventsByCar(smokingEventsLastWeek))
    },

    async getSmokingRank(context) {
      const jwt = context.rootGetters['auth/getUserJwt']
      const smokingRank = await fetchSmokingRank(jwt)
      context.commit('setSmokingRankAll', smokingRank.splice(0, 10))
    }
  }
}
