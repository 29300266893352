  <template>
  <div>
    <v-card max-width="400" class="mb-5">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Reset your password</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear :indeterminate="true" v-show="request"></v-progress-linear>
      <v-card-text>
        <v-form ref="form" max-width="200">
          <ValidationProvider v-slot="{ errors }" rules="required|email" v-model="email" label="E-mail">
            <v-text-field
              prepend-icon="mdi-email"
              v-model="email"
              label="E-mail"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            v-model="confirmCode"
            label="Confirmation Code"
          >
            <v-text-field
              v-if="sent"
              prepend-icon="mdi-lock"
              v-model="confirmCode"
              label="Confirmation Code"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required|min:8" v-model="password" label="Password">
            <v-text-field
              v-if="sent"
              prepend-icon="mdi-lock"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              hint="At least 8 characters"
              v-model="password"
              label="New Password"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <v-card-actions>
            <v-btn v-if="!sent" text align="left" color="primary" @click="signIn">BACK TO SIGNIN</v-btn>
            <v-btn v-if="sent" text align="left" color="primary" @click="forgot">RESEND CODE</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="!sent" color="primary" @click="forgot">SEND CODE</v-btn>
            <v-btn v-if="sent" color="primary" @click="submit">SUBMIT</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

  <script>
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { ValidationProvider } from 'vee-validate'
export default {
  name: 'confirm-sign-up',
  components: {
    ValidationProvider,
  },
  data() {
    return {
      request: false,
      email: '',
      confirmCode: '',
      password: '',
      showPass: false,
      sent: false,
      error: '',
    }
  },
  methods: {
    forgot() {
      this.request = true
      Auth.forgotPassword(this.email)
        .then((data) => {
          console.log(data)
          this.sent = true
        })
        .catch((e) => {
          console.log(e)
        })
      this.request = false
    },
    submit() {
      Auth.forgotPasswordSubmit(this.email, this.confirmCode, this.password)
        .then(() => {
          console.log('forgotPasswordSubmit')
          AmplifyEventBus.$emit('authState', 'signIn')
        })
        .catch((e) => {
          console.log(`forgotPasswordSubmit error ${JSON.stringify(e)}`)
        })
    },
    signIn() {
      AmplifyEventBus.$emit('authState', 'signIn')
    },
    setError: function (e) {
      this.error = this.$Amplify.I18n.get(e.message || e)
      this.logger.error(this.error)
    },
  },
}
</script>

  <style></style>
