import { required, email, max, min, } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("max", {
  ...max,
  message: "This field must be {length} characters or less"
});

extend("min", {
  ...min,
  message: "This field must be at least {length} characters"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});
