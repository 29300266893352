<template>
  <div class="account">
    <v-container class="my-10">
      <v-row>
        <v-col cols="6" lg="6" xs="12">
          <v-card outlined v-show="signedIn">
            <v-card-title>Email: {{ getUserEmail }}</v-card-title>
            <v-card-text class="my-5">Organization: {{ getUserOrg }}</v-card-text>
            <div v-show="stage === 'dev'">
              <v-card-text class="my-5" v-show="signedIn"> JWT for testing: {{ getUserJwt }} </v-card-text>
              <v-card-text> JWT expiry: {{ getUserTokenExpiryTime }} </v-card-text>
              <v-card-text class="my-5"> App Stage = {{ this.$STAGE }} </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

export default {
  name: 'account',
  data() {
    return {
      stage: this.$STAGE,
    }
  },
  computed: {
    signedIn() {
      return this.$store.getters['auth/isSignedIn']
    },
    getUserEmail() {
      return this.$store.getters['auth/getUserEmail']
    },
    getUserOrg() {
      return this.$store.getters['auth/getUserOrg']
    },
    getUserAttr() {
      return this.$store.getters['auth/getUserAttr']
    },
    getUserJwt() {
      return this.$store.getters['auth/getUserJwt']
    },
    getUserTokenExpiryTime() {
      return this.$store.getters['auth/getUserTokenExpiryTime']
    },
  },
}
</script>

<style>
.card-action {
  position: absolute;
  right: 3;
  bottom: 0;
}
</style>