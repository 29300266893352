/* eslint-disable no-console */
<template>
  <div align="center">
    <!-- <amplify-authenticator v-bind:authConfig="authConfig"></amplify-authenticator> -->
    <v-img src="../assets/crispifyLogoTransparent_Small2.png" max-width="200"></v-img>
    <sign-in
      v-if="displayMap.showSignIn || displayMap.showSignOut"
      v-bind:signInConfig="options.signInConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></sign-in>
    <sign-up
      v-if="displayMap.showSignUp"
      v-bind:signUpConfig="options.signUpConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></sign-up>
    <confirm-sign-up
      v-if="displayMap.showConfirmSignUp"
      v-bind:confirmSignUpConfig="options.confirmSignUpConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></confirm-sign-up>
    <reset-password
      v-if="displayMap.showForgotPassword"
      v-bind:forgotPasswordConfig="options.forgotPasswordConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></reset-password>
    <require-new-password
      v-if="displayMap.requireNewPassword"
      v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
    ></require-new-password>
  </div>
</template>

<script>
import { AmplifyEventBus, GetUser } from 'aws-amplify-vue'
import SignUp from '../components/authentication/SignUp'
import SignIn from '../components/authentication/SignIn'
import ConfirmSignUp from '../components/authentication/ConfirmSignUp'
import ResetPassword from '../components/authentication/ResetPassword'
import RequireNewPassword from '../components/authentication/RequireNewPassword'

export default {
  name: 'login',
  components: {
    'sign-up': SignUp,
    'sign-in': SignIn,
    'confirm-sign-up': ConfirmSignUp,
    'reset-password': ResetPassword,
    'require-new-password': RequireNewPassword,
  },
  data() {
    return {
      user: {
        username: null,
      },
      displayMap: {},
      error: '',
      logger: {},
      authConfig: {
        hideAllDefaults: true,
        signInConfig: {},
        signUpConfig: {
          hiddenDefaults: ['phone_number'],
          signUpFields: [
            {
              label: 'Email',
              key: 'email',
              required: true,
              displayOrder: 1,
              type: 'string',
              signUpWith: true,
            },
            {
              label: 'Password',
              key: 'password',
              required: true,
              displayOrder: 2,
              type: 'password',
            },
            {
              label: 'Organization',
              key: 'organization',
              required: true,
              displayOrder: 4,
              type: 'string',
              custom: true,
            },
          ],
        },
      },
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {},
        usernameAttributes: 'username',
      }
      return Object.assign(defaults, this.authConfig || {})
    },
  },
  async mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name)
    AmplifyEventBus.$on('localUser', (user) => {
      this.user = user
      this.options.signInConfig.username = this.user.username
      this.options.confirmSignInConfig.user = this.user
      this.options.confirmSignUpConfig.username = this.user.username
      this.options.requireNewPasswordConfig.user = this.user
    })
    AmplifyEventBus.$on('authState', (data) => {
      this.displayMap = this.updateDisplayMap(data)
    })

    this.$Amplify.Auth.currentAuthenticatedUser()
      .then((val) => {
        if (val instanceof Error) {
          return (this.displayMap = this.updateDisplayMap('signedOut'))
        }
        this.user = val
        return (this.displayMap = this.updateDisplayMap('signedIn'))
      })
      .catch((e) => {
        this.displayMap = this.updateDisplayMap('signedOut')
        this.setError(e)
      })
  },
  methods: {
    updateDisplayMap: (state) => {
      return {
        showSignIn: state === 'signedOut' || state === 'signIn',
        showSignUp: state === 'signUp',
        showConfirmSignUp: state === 'confirmSignUp',
        showConfirmSignIn: state === 'confirmSignIn',
        showForgotPassword: state === 'forgotPassword',
        showSignOut: state === 'signedIn',
        showMfa: state === 'setMfa',
        requireNewPassword: state === 'requireNewPassword',
      }
    },
    setError: function (e) {
      this.error = this.$Amplify.I18n.get(e.message || e)
      this.logger.error(this.error)
    },
  },
}
</script>
