<template>
  <v-card flat>
    <v-row row wrap :class="getStyle(notification.read)">
      <v-col xs2 md3>
        <div class="caption grey--text">Time</div>
        <div>{{ this.getTime(notification.event_start) }}</div>
      </v-col>
      <v-col xs2 md3>
        <div class="caption grey--text">Event</div>
        <div>{{ notification.message }}</div>
      </v-col>

      <v-col lg-1>
        <v-row>
          <v-btn text icon color="grey" @click="changeNotificationStatus(notification)">
            <v-icon v-if="notification.read === true" color="grey">mdi-email-open</v-icon>
            <v-icon v-else color="red lighten-1">email</v-icon>
          </v-btn>
          <v-btn text icon color="grey" @click="deleteNotification(notification)">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { timestampToDate } from '@/helpers/utils'
export default {
  name: 'notifiction-card',
  props: ['notification'],
  methods: {
    changeNotificationStatus(notification) {
      this.$store.dispatch('notifications/changeNotificationStatus', notification)
    },
    deleteNotification(notification) {
      this.$store.dispatch('notifications/deleteNotification', notification)
    },
    getTime(timestamp) {
      return timestampToDate(timestamp)
    },
    getStyle(read) {
      if (read) {
        return 'ma-2 read'
      } else {
        return 'ma-2 unread'
      }
    },
  },
}
</script>

<style>
.unread {
  border-left: 4px solid #ef5350;
}

.read {
  border-left: 4px solid #26a69a;
}
</style>
