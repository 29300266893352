var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.totalDevices > 0)?_c('div',[_c('v-card',{attrs:{"outlined":"","flat":"","align":"center","height":"235"}},[_c('v-icon',{staticClass:"mt-5 mb-1",attrs:{"large":""}},[_vm._v("mdi-developer-board")]),_c('v-card-title',{staticClass:"justify-center pt-1 display-2 font-weight-black"},[_vm._v(_vm._s(_vm.totalDevices))]),_c('v-card-subtitle',{staticClass:"justify-center"},[_vm._v("Total Devices")]),_c('v-divider'),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('span',{staticClass:"caption"},[_vm._v("Active:")]),_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.totalActive))]),_c('vue-svg-progress',{attrs:{"value":_vm.calcProgressValue('active'),"type":"rect","options":{
              rectRadius: 5,
              text: '',
              rectHeight: '10',
              rectWidth: '150',
              pathColors: ['#EEE', '#3cd1c2'],
            }}})],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('v-card',{staticClass:"pb-2",attrs:{"flat":""}},[_c('span',{staticClass:"caption"},[_vm._v("Idle:")]),_c('span',{staticClass:"subtitle-2 align-right"},[_vm._v(_vm._s(_vm.totalIdle))]),_c('vue-svg-progress',{attrs:{"value":_vm.calcProgressValue('idle'),"type":"rect","options":{
              rectRadius: 5,
              text: '',
              rectHeight: '10',
              rectWidth: '150',
              pathColors: ['#EEE', '#ffaa2c'],
            }}})],1)],1)],1)],1)],1):_c('v-skeleton-loader',{attrs:{"type":"card"}})
}
var staticRenderFns = []

export { render, staticRenderFns }