<template>
  <div>
    <notifications-data></notifications-data>
  </div>
</template>

<script>
import NotificationsData from '@/components/notifications/NotificationsData'
export default {
  name: 'notifications',
  components: {
    NotificationsData,
  },
  data() {
    return {}
  },
}
</script>

<style>
</style>