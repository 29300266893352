import { Auth } from 'aws-amplify'

const getDefaultState = () => {
  return {
    user_attr: null,
    user_tokens: null,
    signedIn: false
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    isSignedIn: state => {
      return state.signedIn
    },
    getUserId: state => {
      if (state.user_attr !== null) return state.user_attr.sub
      return ''
    },
    getUserEmail: state => {
      if (state.user_attr !== null) return state.user_attr.email
      return ''
    },
    getUserAttr: state => {
      return JSON.stringify(state.user_attr, null, ' ')
    },
    getUserOrg: state => {
      if (state.user_attr !== null) return state.user_attr['custom:organization'].toLowerCase()
      return ''
    },
    getUserOrgCode: state => {
      if (state.user_attr !== null) return state.user_attr['custom:organization_code']
      return ''
    },
    getUserTokens: state => {
      return JSON.stringify(state.user_tokens, null, ' ')
    },
    getUserJwt: state => {
      if (state.user_tokens !== null) return state.user_tokens.idToken.jwtToken
      else return null
    },
    getUserTokenExpiryTime: state => {
      if (state.user_tokens !== null) return state.user_tokens.idToken.payload.exp
      else return null
    }
  },

  mutations: {
    resetAuthState(state) {
      Object.assign(state, getDefaultState())
    },
    setUser(state, user) {
      console.log('user for auth setUser', user)
      if (user) {
        state.user_attr = user.attributes
        state.user_tokens = user.signInUserSession
        state.signedIn = true
      } else {
        state.user_attr = null
        state.user_tokens = null
        state.signedIn = false
      }
    },
    setSignedIn(state, signedIn) {
      console.log('setSignedIn auth mutation')
      state.signedIn = signedIn
    }
  },
  actions: {
    resetAuthState(context) {
      context.commit('resetAuthState')
    },
    setSignedIn(context, signedIn) {
      context.commit('setSignedIn', signedIn)
    }
  }
}
