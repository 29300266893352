import axios from 'axios'
import AWS from 'aws-sdk'
import { timestampToDate } from './utils'
import { restConfig } from '@/configs/restConfig.js'

function unmarshall(dynamoData) {
  const options = {
    convertEmptyValues: true,
    wrapNumbers: false
  }
  const unmarshalled = AWS.DynamoDB.Converter.unmarshall(dynamoData, options)
  return unmarshalled
}

export async function getAlerts(jwt, start, end) {
  if (!start || !end) {
    throw 'missing start and end dates for query'
  }
  const config = {
    headers: {
      Authorization: jwt
    }
  }
  const url = `${restConfig.smokingDetectionEndpoint}/smoking_events?start=${start}&end=${end}`
  console.log('getAlerts url', url)
  let res = await axios.get(url, config)
  if (!/^2/.test(res.status)) {
    alert(`[${res.status}] Error when trying to generate report.`)
    return []
  }
  if (res.data.length === 0) {
    return []
  }
  const items = res.data.map(e => {
    e.date = timestampToDate(e.event_start)
    return e
  })
  return items
}

export async function getRides(jwt, start, end) {
  if (!start || !end) {
    throw 'missing start and end dates for query'
  }
  const config = {
    headers: {
      Authorization: jwt
    }
  }
  const url = `${restConfig.aggreagtionsEndpoint}/rides?start=${start}&end=${end}`
  console.log(`getRides url ${url}`)
  let res = await axios.get(url, config)
  if (!/^2/.test(res.status)) {
    alert(`[${res.status}] Error when trying to generate report.`)
    return []
  }
  if (res.data.length === 0) {
    return []
  }
  const items = res.data.map(e => {
    e.start_date = timestampToDate(e.session_start)
    e.end_date = timestampToDate(e.session_end)
    return e
  })
  return items
}

export async function fetchSmokingRank(jwt) {
  const config = {
    headers: {
      Authorization: jwt
    }
  }
  const url = `${restConfig.aggreagtionsEndpoint}/smoking_rank`
  const res = await axios.get(url, config)
  if (res.status != 200) {
    console.error(`[${res.status}] Error when trying to fetch smoking rank data`)
    console.log(res)
    return
  }
  console.log('fetchSmokingRank data', res.data)
  return res.data
}
