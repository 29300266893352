<template>
  <div>
    <div v-if="rpt.length > 0">
      <vue-json-excel :data="alerts">
        <v-btn icon color="primary">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
      </vue-json-excel>
      <v-text-field
        v-show="generated"
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        v-show="generate || generated"
        item-key="name"
        class="elevation-1"
        :loading="generate"
        :loading-text="loadingText"
        :headers="headers"
        :items="rpt"
        :search="search"
      ></v-data-table>
    </div>
    <no-data-card v-else :text="noDataMsg" />
  </div>
</template>

<script>
import JsonExcel from 'vue-json-excel'
import NoDataCard from '@/components/common/NoDataCard.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'smoking-reports-table',
  components: {
    'vue-json-excel': JsonExcel,
    'no-data-card': NoDataCard,
  },
  props: ['generate', 'generated', 'alerts'],
  data() {
    return {
      loadingText: 'Loading... Please wait',
      search: '',
      headers: [
        { text: 'Vehicle ID', value: 'vin' },
        { text: 'Date', value: 'date' },
        { text: 'Event', value: 'event_type' },
      ],
      noDataMsg: ' 😬 Oops, We have no data to show...',
    }
  },
  computed: {
    ...mapGetters({ rpt: 'demo/smokingRptData' }),
  },
}
</script>

<style>
</style>