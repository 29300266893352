  <template>
  <div>
    <v-card max-width="400" class="mb-5">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Sign in to your account</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear :indeterminate="true" v-show="request"></v-progress-linear>
      <v-card-text>
        <v-form ref="form" max-width="200">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|email"
            v-model="email"
            label="E-mail"
            name="E-mail"
          >
            <v-text-field
              prepend-icon="mdi-email"
              v-model="email"
              label="E-mail"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|min:8"
            v-model="password"
            label="Password"
            name="Password"
          >
            <v-text-field
              prepend-icon="mdi-lock"
              v-model="password"
              label="Password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <p align="left" class="caption">
            Forgot your password?
            <a @click="forgot">Reset Here...</a>
          </p>
          <v-card-actions>
            <v-btn color="primary" @click="signInUser">SIGN IN</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar color="error" top v-model="alert">
      {{ errorMessage }}
      <v-btn color="black" text @click="alert = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

  <script>
import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
export default {
  name: 'sign-in',
  components: {
    ValidationProvider,
  },
  data() {
    return {
      request: false,
      show: false,
      email: '',
      password: '',
      alert: false,
      errorMessage: '',
    }
  },
  methods: {
    signInUser() {
      this.request = true
      Auth.signIn(this.email, this.password)
        .then((user) => {
          console.log(user)
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            AmplifyEventBus.$emit('localUser', user)
            return AmplifyEventBus.$emit('authState', 'requireNewPassword')
          }
          if (user.challengeName === 'RESET_REQUIRED') {
            AmplifyEventBus.$emit('localUser', user)
            return AmplifyEventBus.$emit('authState', 'forgotPassword')
          }
          this.$store.commit('auth/setUser', user)
          return AmplifyEventBus.$emit('authState', 'signedIn')
        })
        .catch((e) => {
          console.log('signin error', e)
          this.errorMessage = e.message
          this.alert = true
          if (e.code && e.code === 'UserNotConfirmedException') {
            AmplifyEventBus.$emit('localUser', { username: this.email })
            AmplifyEventBus.$emit('authState', 'confirmSignUp')
          } else {
            console.log(e)
            if (e.code === 'UserNotFoundException') {
              AmplifyEventBus.$emit('authState', 'signUp')
            }
          }
        })
      this.request = false
    },
    signUpUser() {
      AmplifyEventBus.$emit('authState', 'signUp')
    },
    forgot() {
      AmplifyEventBus.$emit('authState', 'forgotPassword')
    },
  },
}
</script>

  <style></style>
