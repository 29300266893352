<template>
  <div>
    <v-card dense outlined>
      <p class="subtitle-2 mb-1 text-center">
        {{formatName(p.pollutant)}}
        <sub>[{{getUnits(p.pollutant, p.value)}}]</sub>
      </p>
      <p
        :class="`title text-center mb-1 ${getCategory(p.pollutant, p.value)}`"
      >{{formatValue(p.value)}}</p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="showChart">
          <v-icon>mdi-chart-line</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  getPm10CategoryColor,
  getSmallPmCategoryColor,
  getCo2CategoryColor,
  getVocCategoryColor
} from '../../helpers/getCategoryColors'
export default {
  name: 'pollutant-val-card',
  props: ['p', 'device_id'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    showChart() {
      this.$emit('updateChart', this.p.pollutant)
    },
    formatValue(val) {
      return Math.floor(val)
    },
    formatName(name) {
      let reg = new RegExp('_')
      let nameNo_ = name.replace(reg, ' ')
      return nameNo_.toUpperCase()
    },
    getCategoryColor(pollutant, val) {
      switch (pollutant) {
        case 'pm1_mc':
        case 'pm4_mc':
        case 'pm2p5_mc':
          return getSmallPmCategoryColor(val).color
        case 'pm10_mc':
          return getPm10CategoryColor(val).color
        case 'co2':
          return getCo2CategoryColor(val).color
        case 'voc':
          return getVocCategoryColor(val).color
        default:
          return 'info'
      }
    },
    getCategory(pollutant, val) {
      switch (pollutant) {
        case 'pm1_mc':
        case 'pm4_mc':
        case 'pm2p5_mc':
          return getSmallPmCategoryColor(val).category
        case 'pm10_mc':
          return getPm10CategoryColor(val).category
        case 'co2':
          return getCo2CategoryColor(val).category
        case 'voc':
          return getVocCategoryColor(val).category
        default:
          return 'NO_CATEGORY'
      }
    },
    getUnits(pollutant, val) {
      switch (pollutant) {
        case 'pm2p5_mc':
        case 'pm1_mc':
        case 'pm4_mc':
        case 'pm10_mc':
          return 'ug/m^3'
        case 'co2':
          return 'ppm'
        case 'voc':
          return 'ppb'
        case 'temperature':
          return 'C'
        case 'humidity':
          return '%'
        default:
          return ' '
      }
    }
  },
  mounted() {
    this.showChart()
  }
}
</script>

<style lang="scss" scoped>
</style>