<template>
  <div class="dashboard">
    <v-container>
      <v-row class="fill-height">
        <v-col sm="12" md="6" lg="6">
          <devices-total-card></devices-total-card>
        </v-col>
        <v-col sm="12" md="3" lg="3"> <crisp-score></crisp-score></v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="6" lg="6"> <cleanness></cleanness></v-col>
        <v-col sm="12" md="3" lg="3"> <covid></covid></v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="12" lg="6">
          <smoking-events-trend :smokingEventsMonth="fetchSmokingEvents"></smoking-events-trend>
        </v-col>
        <v-col sm="12" md="12" lg="6">
          <smoking-cars-stacked :smokingEventsMonth="fetchSmokingEvents"></smoking-cars-stacked>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DevicesTotalCard from '../components/dashboard/DevicesTotalCard.vue'
import SmokingEventsTrend from '../components/dashboard/SmokingEventsTrend.vue'
// import LastSmokingEvents from '../components/dashboard/LastSmokingEvents.vue'
// import VinSmokingRank from '../components/dashboard/VinSmokingRank.vue'
import SmokingCarsStacked from '../components/dashboard/SmokingCarsStacked.vue'
// import FleetPollutionCard from '../components/dashboard/FleetPollutionCard.vue'
import CrispScore from '../components/dashboard/CrispScore.vue'
import covid from '@/components/dashboard/Covid.vue'
import cleanness from '@/components/dashboard/Cleanness.vue'
export default {
  name: 'dashboard',
  components: {
    'devices-total-card': DevicesTotalCard,
    'smoking-events-trend': SmokingEventsTrend,
    'smoking-cars-stacked': SmokingCarsStacked,
    'crisp-score': CrispScore,
    // 'last-smoking-events': LastSmokingEvents,
    // 'vin-smoking-rank': VinSmokingRank,
    covid,
    cleanness,
  },
  data() {
    return {}
  },
  computed: {
    getFleetDevicesData() {
      return this.$store.getters['devices/getDevicesData']
    },
    getTop10WorstRidesRange1() {
      return this.$store.getters['insights/getTop10WorstRidesRange1']
    },
    getTop10WorstRidesRange7() {
      return this.$store.getters['insights/getTop10WorstRidesRange7']
    },
    fetchSmokingEvents() {
      return this.$store.getters['insights/getSmokingEventsLastMonth']
    },
  },
  watch: {
    getFleetDevicesData(newVal, oldVal) {},
  },
  methods: {
    switchRideOverlay(ride) {
      this.rideOverlayData = ride
      this.rideOverlay = !this.rideOverlay
    },
  },
}
</script>
