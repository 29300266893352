<template>
  <div>
    <p v-if="isConnected">We're connected to the server!</p>
    <p v-else>"We're not connected to the server!"</p>
    <p>Message from server: "{{this.$store.getters['websocket/getSocketMessage']}}"</p>
  </div>
</template>

<script>
export default {
  name: 'socketListener',
  data() {
    return {
      socketMessage: ''
    }
  },
  computed: {
    wsOnMessage() {
      return this.$store.getters['websocket/getSocketMessage']
    },
    isConnected() {
      return this.$store.getters['websocket/getSocketIsConnected'];
    }
  },
  watch: {
    wsOnMessage(newVal, oldVal) {
      this.socketMessage = newVal;
    }
  }
}
</script>