<template>
  <div v-if="smokingEventsLastMonth">
    <v-card flat outlined>
      <v-card-title class="font-weight-bold">
        Smoking Per Vehicle Breakdown
        <v-spacer></v-spacer>
        <v-card-actions justify="right">
          <v-btn small outlined color="primary" :input-value="currentData(4)" @click="setRange(4)"
            >Last Month</v-btn
          >
          <v-btn small outlined color="primary" :input-value="currentData(1)" @click="setRange(1)"
            >Last Week</v-btn
          >
        </v-card-actions>
      </v-card-title>
      <v-card-text>
        <apexchart type="line" :options="updateChartOptions" :series="updateChartSeries" height="350">
        </apexchart>
      </v-card-text>
    </v-card>
  </div>
  <v-skeleton-loader type="card" v-else></v-skeleton-loader>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters, mapActions, mapState } from 'vuex'
import { binSmokingEventsByCar, binSmokingEventsByCarDemo } from '../../helpers/insights.js'
export default {
  name: 'smoking-cars-stacked',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      range: 1,
      ymax: 50,
      ymin: 0,
      chartOptions: {
        chart: {
          id: 'smoking-cars',
          type: 'line',
          height: '350',
          stacked: true,
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        colors: ['#166864', '#D5ECD4'],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          style: {
            colors: ['#166864'],
          },
        },
        xaxis: {
          type: 'categories',
          categories: [],
        },
        yaxis: [
          {
            title: {
              text: 'Cars where smoking was detected',
            },
            min: this.ymin,
            max: this.ymax,
            decimalsInFloat: 1,
          },
          {
            opposite: true,
            title: {
              text: 'Evnts/Car Ratio',
            },
            min: this.ymin,
            max: this.ymax,
            decimalsInFloat: 1,
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      getUserTokens: 'getUserTokens',
    }),
    ...mapGetters('insights', {
      smokingEventsLastWeek: 'getSmokingEventsLastWeek',
      smokingEventsLastMonth: 'getSmokingEventsLastMonth',
    }),
    binnedEventsByCar() {
      if (this.range === 1) return binSmokingEventsByCar(this.smokingEventsLastWeek, 'week')
      return binSmokingEventsByCar(this.smokingEventsLastMonth, 'month')
    },
    binnedEventsByCarDemo() {
      if (this.range === 1) return binSmokingEventsByCarDemo(this.smokingEventsLastWeek, 'week')
      return binSmokingEventsByCarDemo(this.smokingEventsLastMonth, 'month')
    },
    updateChartSeries() {
      const series = [
        {
          name: 'Cars where smoking was detected',
          type: 'column',
          data: this.binnedEventsByCarDemo.carsCount,
        },
        {
          name: 'Events per Car Ratio',
          type: 'line',
          data: this.binnedEventsByCarDemo.avgEventsPerCar,
        },
      ]
      // console.log(series)
      return series
    },
    updateChartOptions() {
      let newOpts = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.binnedEventsByCar.categories,
          },
        },
      }
      // console.log(newOpts)
      return newOpts
    },
  },
  methods: {
    ...mapActions('insights', {
      getSmokingEventsForTrend: 'getSmokingEventsForTrend',
    }),
    currentData(n) {
      return n === this.range ? true : undefined
    },
    setRange(range) {
      if (range === 1) {
        this.range = 1
      } else {
        this.range = 4
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>