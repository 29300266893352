<template>
  <div>
    <v-card max-width="400" class="mb-5">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Confirm Sign Up</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear :indeterminate="true" v-show="request"></v-progress-linear>
      <v-card-text>
        <v-form ref="form" max-width="200">
          <ValidationProvider
            v-slot="{errors}"
            rules="required|email"
            v-model="email"
            label="E-mail"
          >
            <v-text-field
              prepend-icon="mdi-email"
              v-model="email"
              label="E-mail"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{errors}"
            rules="required"
            v-model="confirmCode"
            label="Confirmation Code"
          >
            <v-text-field
              prepend-icon="mdi-lock"
              v-model="confirmCode"
              label="Confirmation Code"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <p align="left" class="caption">
            Lost your code?
            <a href="/">Resend</a>
          </p>
          <v-card-actions>
            <v-btn text align="left" color="primary" @click="signIn">BACK TO SIGNIN</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="signInUser">CONFIRM</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar color="error" top v-model="alert">
      {{errorMessage}}
      <v-btn color="black" text @click="alert = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
import { ValidationProvider } from "vee-validate";
export default {
  name: "confirm-sign-up",
  components: {
    ValidationProvider
  },
  data() {
    return {
      request: false,
      email: "",
      confirmCode: "",
      alert: false,
      errorMessage: ""
    };
  },
  methods: {
    signInUser() {
      this.request = true;
      Auth.confirmSignUp(this.email, this.confirmCode, {})
        .then(data => {
          console.log(data);
          console.log("confirmSignUp success");
          AmplifyEventBus.$emit("authState", "signIn");
        })
        .catch(err => {
          console.log(err);
          if (err.code == "CodeMismatchException") {
            this.errorMessage = err.message;
            this.alert = true;
          }
        });
      this.request = false;
    },
    signIn() {
      AmplifyEventBus.$emit("authState", "signIn");
    }
  }
};
</script>

<style></style>
