const restConfig = {
  ingestEndpoint: `${process.env.VUE_APP_REST_BASE_URL}` + `${process.env.VUE_APP_INGEST_SERVICE_PATH}`,
  contactEndpoint: `${process.env.VUE_APP_REST_BASE_URL}` + `${process.env.VUE_APP_CONTACT_SERVICE_PATH}`,
  aggreagtionsEndpoint:
    `${process.env.VUE_APP_REST_BASE_URL}` + `${process.env.VUE_APP_AGGREGATIONS_SERVICE_PATH}`,
  notificationsEndpoint:
    `${process.env.VUE_APP_REST_BASE_URL}` + `${process.env.VUE_APP_NOTIFICATIONS_SERVICE_PATH}`,
  smokingDetectionEndpoint:
    `${process.env.VUE_APP_REST_BASE_URL}` + `${process.env.VUE_APP_SMOKING_DETECTION_SERVICE_PATH}`
}

export { restConfig }
