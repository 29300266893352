export function getAqiCategoryColor(aqi) {
    if(aqi <= 50) {
        return {'category': 'GOOD','color': '#166864'};
    } else if (aqi <= 100) {
        return {'category': 'SATISFACTORY','color': '#166864'};
    } else if (aqi <= 200) {
        return {'category': 'MODERATE','color': '#209AA5'};
    } else if (aqi <= 300) {
        return {'category': 'POOR','color': '#D38A54'};
    } else if (aqi <= 400) {
        return {'category': 'VERY_POOR','color': '#DE3E35'};
    } else {
        return {'category': 'SEVERE','color': '#BA1200'};
    }
}

export function getSmallPmCategoryColor(pm) {
    if(pm <= 30) {
        return {'category': 'GOOD','color': '#166864'};
    } else if (pm <= 60) {
        return {'category': 'SATISFACTORY','color': '#166864'};
    } else if (pm <= 90) {
        return {'category': 'MODERATE','color': '#209AA5'};
    } else if (pm <= 120) {
        return {'category': 'POOR','color': '#D38A54'};
    } else if (pm <= 250) {
        return {'category': 'VERY_POOR','color': '#DE3E35'};
    } else {
        return {'category': 'SEVERE','color': '#BA1200'};
    }
}

export function getPm10CategoryColor(pm10) {
    if(pm10 <= 50) {
        return {'category': 'GOOD','color': '#166864'};
    } else if (pm10 <= 100) {
        return {'category': 'SATISFACTORY','color': '#166864'};
    } else if (pm10 <= 250) {
        return {'category': 'MODERATE','color': '#209AA5'};
    } else if (pm10 <= 350) {
        return {'category': 'POOR','color': '#D38A54'};
    } else if (pm10 <= 430) {
        return {'category': 'VERY_POOR','color': '#DE3E35'};
    } else {
        return {'category': 'SEVERE','color': '#BA1200'};
    }
}

export function getVocCategoryColor(voc) {
    if(voc <= 65) {
        return {'category': 'GOOD','color': '#166864'};
    } else if (voc <= 220) {
        return {'category': 'SATISFACTORY','color': '#166864'};
    } else if (voc <= 650) {
        return {'category': 'MODERATE','color': '#209AA5'};
    } else if (voc <= 2200) {
        return {'category': 'POOR','color': '#D38A54'};
    } else if (voc <= 5500) {
        return {'category': 'VERY_POOR','color': '#DE3E35'};
    } else {
        return {'category': 'SEVERE','color': '#BA1200'};
    }
}

export function getCo2CategoryColor(co2) {
    if(co2 <= 500) {
        return {'category': 'GOOD','color': '#166864'};
    } else if (co2 <= 1000) {
        return {'category': 'SATISFACTORY','color': '#166864'};
    } else if (co2 <= 2000) {
        return {'category': 'MODERATE','color': '#209AA5'};
    } else if (co2 <= 5000) {
        return {'category': 'POOR','color': '#D38A54'};
    } else if (co2 <= 40000) {
        return {'category': 'VERY_POOR','color': '#DE3E35'};
    } else {
        return {'category': 'SEVERE','color': '#BA1200'};
    }
}
