export default {
  namespaced: true,
  state: {
    smokingReportStart: '',
    smokingReportEnd: '',
    ridesReportStart: '',
    ridesReportEnd: ''
  },
  getters: {
    getSmokingReportStart: state => {
      return state.smokingReportStart
    },
    getSmokingReportEnd: state => {
      return state.smokingReportEnd
    },
    getRidesReportStart: state => {
      return state.ridesReportStart
    },
    getRidesReportEnd: state => {
      return state.ridesReportEnd
    }
  },
  mutations: {
    setSmokingReportDates(state, payload) {
      state.smokingReportStart = payload.start
      state.smokingReportEnd = payload.end
    },
    setSmokingReportStart(state, ts) {
      state.smokingReportStart = ts
    },
    setSmokingReportEnd(state, ts) {
      state.smokingReportEnd = ts
    },
    setRidesReportDates(state, payload) {
      state.ridesReportStart = payload.start
      state.ridesReportEnd = payload.end
    },
    setRidesReportStart(state, ts) {
      state.ridesReportStart = ts
    },
    setRidesReportEnd(state, ts) {
      state.ridesReportEnd = ts
    }
  },
  actions: {}
}
