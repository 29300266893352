<template>
  <div>
    <v-card flat>
      <v-row row wrap justify="center">
        <v-col cols="2">
          <div class="caption grey--text pl-3">Vehicle ID</div>
          <div class="pl-3">{{ this.data.device }}</div>
        </v-col>
        <v-col cols="2">
          <div class="caption grey--text">Dust</div>
          <div>{{ this.data.pm10 }}</div>
        </v-col>
        <v-col cols="2">
          <div class="caption grey--text">Fine Particles</div>
          <div>{{ this.data.pm25 }}</div>
        </v-col>
        <v-col cols="2">
          <div class="caption grey--text">Chemicals</div>
          <div>{{ this.data.voc }}</div>
        </v-col>
        <v-col cols="1">
          <div class="caption grey--text">CO2</div>
          <div>{{ this.data.co2 }}</div>
        </v-col>
        <v-col cols="1">
          <div class="caption grey--text">Temperature</div>
          <div>{{ this.data.temp }}</div>
        </v-col>
        <v-col cols="1">
          <div class="caption grey--text">Humidity</div>
          <div>{{ this.data.humidity }}</div>
        </v-col>
        <v-col cols="1" align-self="center">
          <device-aq-popup></device-aq-popup>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import deviceAqPopup from './deviceAqPopup.vue'
export default {
  name: 'device-card',
  components: {
    'device-aq-popup': deviceAqPopup,
  },
  props: ['data'],
  data() {
    return {
      device: '123456',
      pm10: 12,
      pm25: 15,
      voc: 0,
      co2: 400,
      temp: 23,
      humid: 40,
    }
  },
}
</script>

<style>
</style>