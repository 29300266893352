<template>
  <div>
    <v-card>
      <v-card-title class="rpt_card_title">Smoking Report</v-card-title>
      <v-card-subtitle>Generate smoking alert summary report for the given date range</v-card-subtitle>
      <v-row class="mx-auto">
        <v-col cols="6">
          <date-picker ref="start" :demoLabel="startDate" @setDate="getStartDate"></date-picker>
        </v-col>
        <v-col cols="6">
          <date-picker ref="end" :demoLabel="endDate" @setDate="getEndDate"></date-picker>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn text align="left" color="primary" :disabled="disableBtn" @click="genReport"
          >Generate Report</v-btn
        >
      </v-card-actions>
      <v-snackbar color="error" top v-model="error">
        {{ errorMessage }}
        <v-btn color="black" text @click="error = false">Close</v-btn>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import DatePicker from './DatePicker'
import { dateStrToTimestamp } from '../../helpers/utils'
export default {
  name: 'smoking-report-menu',
  components: {
    'date-picker': DatePicker,
  },
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    startTs: '',
    endTs: '',
    startMenu: false,
    errorMessage: '',
    error: false,
  }),
  computed: {
    startDate() {
      const now = new Date()
      const start = now.getTime() - 604800 * 1000
      const startDate = new Date(start).toISOString()
      return startDate.split('T')[0]
    },
    endDate() {
      const now = new Date()
      return now.toISOString().split('T')[0]
    },
    disableBtn() {
      if (this.startDate && this.endDate) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    genReport() {
      // this.$store.commit('reports/setSmokingReportDates', payload);
      // if (!this.validateDates()) {
      //   return
      // }
      this.$emit('genSmokingRpt')
    },
    getStartDate(date) {
      this.startDate = date
      this.startTs = dateStrToTimestamp(date)
      this.$store.commit('reports/setSmokingReportStart', this.startTs)
    },
    getEndDate(date) {
      this.endDate = date
      this.endTs = dateStrToTimestamp(date) + 86400
      this.$store.commit('reports/setSmokingReportEnd', this.endTs)
    },
    validateDates() {
      if (this.startDate != '' && this.endDate != '' && this.startTs < this.endTs) {
        return true
      } else {
        this.errorMessage = 'Please check your dates'
        this.error = true
        return false
      }
    },
  },
}
</script>

<style scoped>
.rpt_card_title {
  color: #166864;
}
</style>
