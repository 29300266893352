<template>
  <div>
    <v-card max-width="400" class="mb-5">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Please change your password</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear :indeterminate="true" v-show="request"></v-progress-linear>
      <v-card-text>
        <v-form ref="form" max-width="200">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|min:8"
            v-model="newPassword"
            label="Password"
            name="newPassword"
          >
            <v-text-field
              prepend-icon="mdi-lock"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              hint="At least 8 characters"
              v-model="newPassword"
              label="Password"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <v-card-actions>
            <v-btn text align="left" color="primary" @click="backToSignIn">BACK TO SIGN IN</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submit">SUBMIT</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar color="error" top v-model="alert">
      {{ errorMessage }}
      <v-btn color="black" text @click="alert = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { ValidationProvider } from 'vee-validate'
export default {
  name: 'require-new-password',
  components: {
    ValidationProvider,
  },
  data() {
    return {
      request: false,
      newPassword: '',
      showPass: false,
      alert: false,
      errorMessage: '',
    }
  },
  methods: {
    submit() {
      const user = this.$attrs.requireNewPasswordConfig.user
      const { requiredAttributes } = user.challengeParam
      if (requiredAttributes.length > 0) console.error('Missing required attributes for user')
      Auth.completeNewPassword(user, this.newPassword)
        .then((user) => {
          // at this time the user is logged in if no MFA required
          // this.$store.commit('auth/setUser', user)
          return AmplifyEventBus.$emit('authState', 'signIn')
        })
        .catch((e) => {
          console.error(e)
        })
    },
    backToSignIn() {
      AmplifyEventBus.$emit('authState', 'signIn')
    },
  },
}
</script>

<style></style>
