<template>
  <div v-if="notifications.length > 0">
    <notification-card
      v-for="(notification, id) in notifications"
      :key="id"
      :notification="notification"
    ></notification-card>
  </div>
  <div v-else class="fill-height">
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" align="center" justify="center">
          <v-row align="center" justify="center">
            <v-card outlined justify-center color="grey lighten-5">
              <v-card-title center class="justify-center teal--text darken-3 display-2"
                >- No Notifications -</v-card-title
              >
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NotificationCard from './NotificationCard'
export default {
  name: 'notifications-data',
  components: {
    NotificationCard,
  },
  data() {
    return {}
  },
  computed: {
    notifications() {
      return this.$store.getters['notifications/getNotifications']
    },
  },
}
</script>

<style></style>
