<template>
  <td :colspan="colspan">
    <v-row dense class="mt-3">
      <v-col v-for="p in device_data" :key="p.pollutant" lg="1" md="2" sm="4">
        <pollutant-val :p="p" :device_id="device_id" @updateChart="updateChart"></pollutant-val>
      </v-col>
      <v-col v-if="this.$STAGE === 'dev'">
        <p>Smoking score: {{ device_smoking_score }}</p>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <device-pollution-trend
          :device_id="device_id"
          :pollutant="pollutantForChart"
        ></device-pollution-trend>
      </v-row>
    </v-container>
  </td>
</template>

<script>
import {
  getPm10CategoryColor,
  getSmallPmCategoryColor,
  getCo2CategoryColor,
  getVocCategoryColor,
} from '../../helpers/getCategoryColors'
import DevicePollutionTrend from './DevicePollutionTrend'
import PollutantVal from './PollutantVal'
import { mapGetters } from 'vuex'

export default {
  name: 'device-pollution-details',
  components: {
    DevicePollutionTrend,
    PollutantVal,
  },
  props: ['device_data', 'colspan', 'device_id', 'lastMsgTs', 'device_smoking_score'],
  // watch: {
  //   device_id(newVal, oldVal) {
  //     console.log('device_id watcher fetchDeviceHistory')
  //     if (!this.getFetchedDevices.includes(newVal)) {
  //       this.$store.dispatch('pollutionHistory/fetchDeviceHistory', {
  //         timeWin: this.timeWin,
  //         device_id: this.device_id,
  //         lastMsgTime: this.lastMsgTs
  //       })
  //     }
  //   }
  // },
  data() {
    return {
      pollutantForChart: '',
      timeWin: 50, // will get data 5 hours back
    }
  },
  computed: {
    ...mapGetters({
      getFetchedDevices: 'pollutionHistory/getFetchedDevices',
    }),
  },
  methods: {
    updateChart(p) {
      this.pollutantForChart = p
    },
    formatValue(val) {
      return Math.floor(val)
    },
    formatName(name) {
      let reg = new RegExp('_')
      let nameNo_ = name.replace(reg, ' ')
      return nameNo_.toUpperCase()
    },
    getCategoryColor(pollutant, val) {
      switch (pollutant) {
        case 'pm1_mc':
        case 'pm4_mc':
        case 'pm2p5_mc':
          return getSmallPmCategoryColor(val).color
        case 'pm10_mc':
          return getPm10CategoryColor(val).color
        case 'co2':
          return getCo2CategoryColor(val).color
        case 'voc':
          return getVocCategoryColor(val).color
        default:
          return 'info'
      }
    },
    getCategory(pollutant, val) {
      switch (pollutant) {
        case 'pm1_mc':
        case 'pm4_mc':
        case 'pm2p5_mc':
          return getSmallPmCategoryColor(val).category
        case 'pm10_mc':
          return getPm10CategoryColor(val).category
        case 'co2':
          return getCo2CategoryColor(val).category
        case 'voc':
          return getVocCategoryColor(val).category
        default:
          return 'NO_CATEGORY'
      }
    },
    getUnits(pollutant, val) {
      switch (pollutant) {
        case 'pm2p5_mc':
        case 'pm1_mc':
        case 'pm4_mc':
        case 'pm10_mc':
          return 'ug/m^3'
        case 'co2':
          return 'ppm'
        case 'voc':
          return 'ppb'
        case 'temperature':
          return 'C'
        case 'humidity':
          return '%'
        default:
          return ' '
      }
    },
  },
  mounted() {
    if (!this.getFetchedDevices.includes(this.device_id)) {
      this.$store.dispatch('pollutionHistory/fetchDeviceHistory', {
        timeWin: this.timeWin,
        device_id: this.device_id,
        lastMsgTime: this.lastMsgTs,
      })
      this.history_fetched = true
    }
  },
}
</script>

<style>
.POLLUTION_VALS {
  font-size: 20;
}
.GOOD {
  color: #166864;
}
.SATISFACTORY {
  color: #166864;
}
.MODERATE {
  color: #209aa5;
}
.POOR {
  color: #d38a54;
}
.VERY_POOR {
  color: #de3e35;
}
.SEVERE {
  color: #ba1200;
}
.NO_CATEGORY {
  color: grey;
  /* color: #166864 */
}
</style>
