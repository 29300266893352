import Vue from 'vue'
import { getStatus, timestampToDate, getSmokingStatus } from '../../helpers/utils'

export default {
  namespaced: true,
  state: {
    isConnected: false,
    message: '',
    reconnectError: false
  },
  getters: {
    getSocketIsConnected: state => {
      return state.isConnected
    },
    getSocketMessage: state => {
      return state.message
    }
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      console.log('SOCKET_ONOPEN')
      if (!state.isConnected) {
        Vue.prototype.$socket = event.currentTarget
        state.isConnected = true
      }
    },
    SOCKET_ONCLOSE(state, event) {
      console.log('SOCKET_ONCLOSE', state, event)
      state.isConnected = false
      Vue.prototype.$socket = null
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      // console.log(`SOCKET_ONMESSAGE mutation: ${JSON.stringify(message)}`)
      state.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.reconnectError = true
    }
  },
  actions: {
    sendMessage(context, message) {
      console.log('Sending WS message: ' + message)
      Vue.prototype.$socket.send(message)
    },
    updateDevicesFromWs(context, message) {
      let id = message.device_id
      message.status = getStatus(message.timestamp)
      message.last_report_time = timestampToDate(message.timestamp)
      message.smoking_status = getSmokingStatus(message.smoking_score)
      if (context.rootState.devices.devices_id.includes(id)) {
        let i = context.rootState.devices.devices.findIndex(device => device.device_id === id)
        context.dispatch('devices/wsUpdateDevice', { i, message }, { root: true })
      } else {
        // context.dispatch('devices/wsPushDevice', message, { root: true })
      }
    }
  }
}
