<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="demoLabel"
        prepend-icon="event"
        readonly
        v-on="on"
        v-bind="attrs"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      :allowed-dates="allowedDates"
      color="primary"
      @input="passDate(date)"
      disabled
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'date-picker',
  props: ['demoLabel'],
  data: () => ({
    date: '',
    today: new Date().toISOString().substr(0, 10),
    menu: false,
  }),
  computed: {
    setLabel() {
      return this.textLabel + ' Date'
    },
  },
  methods: {
    passDate(date) {
      this.$refs.menu.save(date)
      this.$emit('setDate', this.date)
      this.menu = false
    },
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
  },
}
</script>

<style>
</style>
