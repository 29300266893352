<template>
  <div v-if="show">
    <v-card outlined flat align="center" height="235">
      <v-icon large class="mt-5 mb-1" color="primary">mdi-virus</v-icon>

      <v-card-title class="justify-center pt-1 display-2 font-weight-black"></v-card-title>
      <v-card-subtitle class="justify-center">Covid-19 Infection risk levels</v-card-subtitle>
      <v-card-subtitle class="justify-center display-2">
        {{ this.covidCount[3] + '/' + this.devicesTotal }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="gotoCovid()">search</v-icon>
          </template>
          <span>Investigate</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-card-text>Devices detected with high infection risk</v-card-text>
    </v-card>
  </div>
  <v-skeleton-loader type="card" v-else></v-skeleton-loader>
</template>

<script>
import router from '@/router'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      show: true,
    }
  },
  computed: {
    ...mapGetters({ covidCount: 'demo/covidSeries', devicesTotal: 'demo/devicesTotal' }),
  },
  methods: {
    gotoCovid: function () {
      router.push('/covid')
    },
  },
  mounted() {
    this.$store.commit('demo/covidSeries')
    return true
  },
}
</script>

<style>
</style>
