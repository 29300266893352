<template>
  <div class="fill-height">
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" align="center" justify="center">
          <v-row align="center" justify="center">
            <v-card outlined justify-center color="grey lighten-4">
              <v-card-title center class="justify-center teal--text darken-3 display-2">{{
                this.text
              }}</v-card-title>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'no-data-card',
  props: ['text'],
}
</script>

<style>
</style>