<template>
  <div>
    <v-card class="mx-auto" max-width="400" justify="left" color="white" flat outlined>
      <v-card-text>
        <v-sheet color="white">
          <apexchart type="line" :options="updateChartOptions" :series="updateChartSeries"></apexchart>
        </v-sheet>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'device-pollution-trend',
  components: {
    apexchart: VueApexCharts
  },
  props: ['device_id', 'pollutant'],
  data() {
    return {
      ymax: 1000,
      ymin: 0,
      options: {
        chart: {
          height: '200',
          id: 'pollutant-trend',
          type: 'line',
          sparkline: {
            enabled: false
          }
        },
        title: {
          text: 'pollution trend',
          align: 'left'
        },
        colors: ['#166864'],
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#166864']
          }
        },
        xaxis: {
          type: 'categories',
          categories: []
        },
        yaxis: {
          show: true,
          min: 0,
          max: 800
        },
        stroke: {
          curve: 'smooth'
        }
      }
    }
  },
  computed: {
    updateChartSeries() {
      let history = this.$store.getters['pollutionHistory/getDeviceHistory'](
        this.device_id
      )
      let data = []
      if (history.length > 0) {
        data = history.map(e => e.pollution_data[this.pollutant])
        // TODO: if we want to show times on X axis need to get the e.timestamp values and create an array of two items arrays: [[data1, timestamp1],[data2,timestamp2]and pass that to the series prop of the chart
      }
      this.updateY(data)
      return [
        {
          name: this.pollutant,
          data: data
        }
      ]
    },
    updateChartOptions() {
      let newOpts = this.options
      let newTitle = this.pollutant + ' trendline'
      let ymax = this.ymax
      let ymin = this.ymin
      return {
        ...this.options,
        ...{
          yaxis: {
            ymax: this.ymax,
            ymin: this.ymin,
            decimalsInFloat: 1
          },
          title: {
            text: newTitle
          }
        }
      }
    }
  },
  methods: {
    updateY(data) {
      let ymax = Math.max(...data)
      let ymin = Math.min(...data)
      this.ymax = ymax + 50
      if (ymax === ymin) {
        this.ymin = 0
      } else {
        this.ymin = ymin
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>