export default {
  namespaced: true,

  state: {
    devices: [
      {
        device: 16911086,
        pm10: 26,
        pm25: 23,
        voc: 6,
        co2: 555,
        temp: 18,
        humidity: 45,
        infection_risk: 1,
        cleanness_score: 1
      },
      {
        device: 26577662,
        pm10: 6,
        pm25: 46,
        voc: 5,
        co2: 455,
        temp: 19,
        humidity: 45,
        infection_risk: 3,
        cleanness_score: 2
      },
      {
        device: 28328120,
        pm10: 105,
        pm25: 62,
        voc: 92,
        co2: 446,
        temp: 25,
        humidity: 50,
        infection_risk: 3,
        cleanness_score: 3
      },
      {
        device: 29189178,
        pm10: 44,
        pm25: 21,
        voc: 100,
        co2: 647,
        temp: 25,
        humidity: 33,
        infection_risk: 4,
        cleanness_score: 1
      },
      {
        device: 54844767,
        pm10: 112,
        pm25: 149,
        voc: 1,
        co2: 681,
        temp: 20,
        humidity: 40,
        infection_risk: 2,
        cleanness_score: 1
      },
      {
        device: 34201336,
        pm10: 117,
        pm25: 65,
        voc: 54,
        co2: 479,
        temp: 23,
        humidity: 41,
        infection_risk: 2,
        cleanness_score: 2
      },
      {
        device: 51015615,
        pm10: 90,
        pm25: 61,
        voc: 53,
        co2: 490,
        temp: 18,
        humidity: 44,
        infection_risk: 1,
        cleanness_score: 1
      },
      {
        device: 29891617,
        pm10: 118,
        pm25: 185,
        voc: 1,
        co2: 639,
        temp: 22,
        humidity: 32,
        infection_risk: 1,
        cleanness_score: 3
      },
      {
        device: 80127873,
        pm10: 171,
        pm25: 65,
        voc: 24,
        co2: 559,
        temp: 18,
        humidity: 47,
        infection_risk: 2,
        cleanness_score: 3
      },
      {
        device: 67863720,
        pm10: 5,
        pm25: 144,
        voc: 39,
        co2: 580,
        temp: 20,
        humidity: 50,
        infection_risk: 2,
        cleanness_score: 1
      },
      {
        device: 15439671,
        pm10: 84,
        pm25: 192,
        voc: 68,
        co2: 470,
        temp: 27,
        humidity: 39,
        infection_risk: 4,
        cleanness_score: 2
      },
      {
        device: 94821339,
        pm10: 67,
        pm25: 17,
        voc: 18,
        co2: 785,
        temp: 28,
        humidity: 42,
        infection_risk: 3,
        cleanness_score: 2
      },
      {
        device: 44478127,
        pm10: 76,
        pm25: 34,
        voc: 38,
        co2: 603,
        temp: 30,
        humidity: 49,
        infection_risk: 2,
        cleanness_score: 2
      },
      {
        device: 37794368,
        pm10: 47,
        pm25: 47,
        voc: 44,
        co2: 496,
        temp: 18,
        humidity: 46,
        infection_risk: 3,
        cleanness_score: 3
      },
      {
        device: 94612036,
        pm10: 134,
        pm25: 143,
        voc: 52,
        co2: 788,
        temp: 26,
        humidity: 34,
        infection_risk: 4,
        cleanness_score: 3
      },
      {
        device: 83314577,
        pm10: 194,
        pm25: 108,
        voc: 13,
        co2: 471,
        temp: 24,
        humidity: 46,
        infection_risk: 1,
        cleanness_score: 1
      },
      {
        device: 71792504,
        pm10: 56,
        pm25: 191,
        voc: 76,
        co2: 581,
        temp: 25,
        humidity: 35,
        infection_risk: 3,
        cleanness_score: 2
      },
      {
        device: 44059305,
        pm10: 75,
        pm25: 54,
        voc: 12,
        co2: 581,
        temp: 29,
        humidity: 50,
        infection_risk: 4,
        cleanness_score: 2
      },
      {
        device: 85689554,
        pm10: 170,
        pm25: 119,
        voc: 13,
        co2: 463,
        temp: 23,
        humidity: 33,
        infection_risk: 2,
        cleanness_score: 2
      },
      {
        device: 16829256,
        pm10: 88,
        pm25: 137,
        voc: 21,
        co2: 423,
        temp: 29,
        humidity: 49,
        infection_risk: 1,
        cleanness_score: 1
      },
      {
        device: 90936782,
        pm10: 147,
        pm25: 57,
        voc: 19,
        co2: 487,
        temp: 19,
        humidity: 46,
        infection_risk: 3,
        cleanness_score: 1
      },
      {
        device: 81273598,
        pm10: 43,
        pm25: 64,
        voc: 53,
        co2: 796,
        temp: 29,
        humidity: 40,
        infection_risk: 4,
        cleanness_score: 1
      },
      {
        device: 37656524,
        pm10: 181,
        pm25: 116,
        voc: 19,
        co2: 640,
        temp: 20,
        humidity: 36,
        infection_risk: 3,
        cleanness_score: 2
      },
      {
        device: 99389078,
        pm10: 172,
        pm25: 199,
        voc: 75,
        co2: 442,
        temp: 27,
        humidity: 48,
        infection_risk: 3,
        cleanness_score: 3
      },
      {
        device: 89033031,
        pm10: 137,
        pm25: 155,
        voc: 60,
        co2: 419,
        temp: 18,
        humidity: 33,
        infection_risk: 1,
        cleanness_score: 1
      },
      {
        device: 28390626,
        pm10: 162,
        pm25: 6,
        voc: 38,
        co2: 660,
        temp: 22,
        humidity: 46,
        infection_risk: 1,
        cleanness_score: 3
      },
      {
        device: 66057494,
        pm10: 106,
        pm25: 197,
        voc: 91,
        co2: 456,
        temp: 26,
        humidity: 37,
        infection_risk: 4,
        cleanness_score: 1
      },
      {
        device: 93540387,
        pm10: 84,
        pm25: 2,
        voc: 52,
        co2: 595,
        temp: 18,
        humidity: 39,
        infection_risk: 1,
        cleanness_score: 1
      },
      {
        device: 63588682,
        pm10: 115,
        pm25: 87,
        voc: 37,
        co2: 609,
        temp: 30,
        humidity: 34,
        infection_risk: 3,
        cleanness_score: 1
      },
      {
        device: 87917683,
        pm10: 75,
        pm25: 13,
        voc: 23,
        co2: 762,
        temp: 29,
        humidity: 33,
        infection_risk: 2,
        cleanness_score: 2
      },
      {
        device: 61967401,
        pm10: 41,
        pm25: 196,
        voc: 32,
        co2: 560,
        temp: 18,
        humidity: 37,
        infection_risk: 4,
        cleanness_score: 1
      },
      {
        device: 27677174,
        pm10: 151,
        pm25: 70,
        voc: 98,
        co2: 406,
        temp: 19,
        humidity: 46,
        infection_risk: 2,
        cleanness_score: 2
      },
      {
        device: 59124041,
        pm10: 76,
        pm25: 48,
        voc: 60,
        co2: 793,
        temp: 20,
        humidity: 40,
        infection_risk: 2,
        cleanness_score: 1
      },
      {
        device: 36561822,
        pm10: 171,
        pm25: 129,
        voc: 65,
        co2: 456,
        temp: 29,
        humidity: 38,
        infection_risk: 4,
        cleanness_score: 2
      },
      {
        device: 30048036,
        pm10: 195,
        pm25: 132,
        voc: 18,
        co2: 467,
        temp: 21,
        humidity: 33,
        infection_risk: 4,
        cleanness_score: 1
      },
      {
        device: 40306901,
        pm10: 195,
        pm25: 134,
        voc: 85,
        co2: 748,
        temp: 27,
        humidity: 50,
        infection_risk: 1,
        cleanness_score: 3
      },
      {
        device: 44024004,
        pm10: 63,
        pm25: 191,
        voc: 74,
        co2: 636,
        temp: 23,
        humidity: 34,
        infection_risk: 1,
        cleanness_score: 3
      },
      {
        device: 84853678,
        pm10: 14,
        pm25: 120,
        voc: 47,
        co2: 536,
        temp: 30,
        humidity: 32,
        infection_risk: 4,
        cleanness_score: 2
      },
      {
        device: 98451072,
        pm10: 141,
        pm25: 180,
        voc: 65,
        co2: 461,
        temp: 24,
        humidity: 41,
        infection_risk: 3,
        cleanness_score: 1
      },
      {
        device: 68018538,
        pm10: 110,
        pm25: 182,
        voc: 51,
        co2: 707,
        temp: 29,
        humidity: 43,
        infection_risk: 4,
        cleanness_score: 3
      },
      {
        device: 12954611,
        pm10: 171,
        pm25: 2,
        voc: 20,
        co2: 709,
        temp: 21,
        humidity: 38,
        infection_risk: 2,
        cleanness_score: 2
      },
      {
        device: 77657953,
        pm10: 70,
        pm25: 2,
        voc: 69,
        co2: 644,
        temp: 18,
        humidity: 35,
        infection_risk: 2,
        cleanness_score: 2
      },
      {
        device: 24879790,
        pm10: 72,
        pm25: 145,
        voc: 45,
        co2: 530,
        temp: 28,
        humidity: 46,
        infection_risk: 1,
        cleanness_score: 1
      },
      {
        device: 46517634,
        pm10: 10,
        pm25: 51,
        voc: 18,
        co2: 659,
        temp: 25,
        humidity: 38,
        infection_risk: 1,
        cleanness_score: 3
      },
      {
        device: 71761074,
        pm10: 74,
        pm25: 139,
        voc: 53,
        co2: 684,
        temp: 20,
        humidity: 48,
        infection_risk: 2,
        cleanness_score: 2
      }
    ],
    covidSeries: [],
    cleannessSeries: [],
    smokingRptData: [
      {
        org: '00',
        event_start_time: '2021-04-10T19:59:02.000Z',
        event_type: 'smoking',
        event_end_time: '2021-04-10T20:03:18.000Z',
        event_end: 1618084998,
        vin: '37656524',
        event_start: 1618084742,
        date: 'Apr-10-2021 19:59:02'
      },
      {
        org: '00',
        event_start_time: '2021-04-10T18:02:13.000Z',
        event_type: 'smoking',
        event_end_time: '2021-04-10T18:07:36.000Z',
        event_end: 1618078056,
        vin: '37656524',
        event_start: 1618077733,
        date: 'Apr-10-2021 18:02:13'
      },
      {
        org: '00',
        event_start_time: '2021-04-08T11:05:44.000Z',
        event_type: 'smoking',
        event_end_time: '2021-04-08T11:09:12.000Z',
        event_end: 1617880152,
        vin: '44059305',
        event_start: 1617879944,
        date: 'Apr-08-2021 11:05:44'
      },
      {
        org: '00',
        event_start_time: '2021-04-07T08:12:09.000Z',
        event_type: 'smoking',
        event_end_time: '2021-04-07T08:15:55.000Z',
        event_end: 1617783355,
        vin: '44478127',
        event_start: 1617783129,
        date: 'Apr-07-2021 08:12:09'
      },
      {
        org: '00',
        event_start_time: '2021-04-06T23:59:02.000Z',
        event_type: 'smoking',
        event_end_time: '2021-04-07T00:03:51.000Z',
        event_end: 1617753831,
        vin: '37656524',
        event_start: 1617753542,
        date: 'Apr-06-2021 23:59:02'
      }
    ]
  },

  getters: {
    devicesTotal: state => {
      return state.devices.length
    },
    devicesDemoData: state => {
      return state.devices
    },
    sortedDevicesDemoDataByInfectionRisk: state => {
      let _ = state.devices
      return _.slice().sort((a, b) => b.infection_risk - a.infection_risk)
    },
    sortedDevicesDemoDataByCleanness: state => {
      let _ = state.devices
      return _.slice().sort((a, b) => b.cleanness_score - a.cleanness_score)
    },
    covidSeries: state => {
      return state.covidSeries
    },
    cleannessSeries: state => {
      return state.cleannessSeries
    },
    requiresMaintenance: state => {
      return state.devices.filter(d => d.cleanness_score > 2)
    },
    smokingRptData: state => {
      return state.smokingRptData
    }
  },
  mutations: {
    covidSeries: state => {
      let res = [0, 0, 0, 0]
      state.devices.map(d => {
        if (d.infection_risk === 1) res[0]++
        if (d.infection_risk === 2) res[1]++
        if (d.infection_risk === 3) res[2]++
        if (d.infection_risk === 4) res[3]++
      })
      state.covidSeries = res
    },
    cleannessSeries: state => {
      let res = [0, 0, 0]
      state.devices.map(d => {
        if (d.cleanness_score === 1) res[0]++
        if (d.cleanness_score === 2) res[1]++
        if (d.cleanness_score === 3) res[2]++
      })
      state.cleannessSeries = res
    }
  }
}
