import Vue from 'vue'
import axios from 'axios'
import { getStatus, timestampToDate, getSmokingStatus, genNotification } from '../../helpers/utils'
import { restConfig } from '@/configs/restConfig'

export default {
  namespaced: true,
  state: {
    devices: [
      {
        pollution_data: {
          co2: 400,
          pm4_mc: 15.56,
          pm4_nc: 36.92,
          pm0p5_nc: 17.458,
          voc: 0,
          pm1_nc: 29.614,
          pm1_mc: 4.532,
          temperature: 19.12,
          humidity: 25.53,
          pm10_mc: 16.524,
          pm2p5_nc: 35.781,
          pm2p5_mc: 10.744,
          pm10_nc: 37.09
        },
        timestamp: 1612492705,
        vin: '70342823',
        device_id: 'NS20470007',
        time: '2021-02-05T02:38:25.000Z',
        aqi_data: {
          co2_aqi: 0,
          dominant_pollutant: 'pm25',
          aqi: 18,
          voc_aqi: 0,
          pollution_category: 'good',
          pm25_aqi: 18,
          pm10_aqi: 17
        },
        smoking_detected: false,
        status: '24hrs Idle',
        last_report_time: 'Feb-5-2021 4:38:25',
        smoking_status: false
      },
      {
        pollution_data: {
          co2: 739,
          pm4_mc: 2.94,
          pm4_nc: 16.741,
          pm0p5_nc: 13.475,
          voc: 0,
          pm1_nc: 16.179,
          pm1_mc: 2.09,
          temperature: 22.79,
          humidity: 33.66,
          pm10_mc: 3.005,
          pm2p5_nc: 16.655,
          pm2p5_mc: 2.613,
          pm10_nc: 16.755
        },
        timestamp: 1616389619,
        vin: '91142450',
        device_id: 'NS20470002',
        time: '2021-03-22T05:06:59.000Z',
        aqi_data: {
          co2_aqi: 74,
          dominant_pollutant: 'co2',
          aqi: 74,
          voc_aqi: 0,
          pollution_category: 'satisfactory',
          pm25_aqi: 5,
          pm10_aqi: 3
        },
        smoking_detected: false,
        status: 'idle',
        last_report_time: 'Mar-22-2021 7:06:59',
        smoking_status: false
      },
      {
        pollution_data: {
          co2: 610,
          pm4_mc: 6.044,
          pm4_nc: 43.097,
          pm0p5_nc: 36.741,
          voc: 390,
          pm1_nc: 42.71,
          pm1_mc: 5.395,
          temperature: 25.16,
          humidity: 30.11,
          pm10_mc: 6.074,
          pm2p5_nc: 43.048,
          pm2p5_mc: 5.893,
          pm10_nc: 43.109
        },
        timestamp: 1616088778,
        vin: '90614182',
        device_id: 'NS20470047',
        time: '2021-03-18T17:32:58.000Z',
        aqi_data: {
          co2_aqi: 61,
          dominant_pollutant: 'voc',
          aqi: 140,
          voc_aqi: 140,
          pollution_category: 'moderate',
          pm25_aqi: 10,
          pm10_aqi: 6
        },
        smoking_detected: false,
        status: '24hrs Idle',
        last_report_time: 'Mar-18-2021 19:32:58',
        smoking_status: false
      },
      {
        pollution_data: {
          co2: 538,
          pm4_mc: 7.901,
          pm4_nc: 59.654,
          pm0p5_nc: 51.909,
          voc: 0,
          pm1_nc: 59.428,
          pm1_mc: 7.472,
          temperature: 37,
          humidity: 11.49,
          pm10_mc: 7.901,
          pm2p5_nc: 59.631,
          pm2p5_mc: 7.901,
          pm10_nc: 59.668
        },
        timestamp: 1615399319,
        vin: '94676971',
        device_id: 'NS20470006',
        time: '2021-03-10T18:01:59.000Z',
        aqi_data: {
          co2_aqi: 54,
          dominant_pollutant: 'co2',
          aqi: 54,
          voc_aqi: 0,
          pollution_category: 'satisfactory',
          pm25_aqi: 13,
          pm10_aqi: 8
        },
        smoking_detected: false,
        status: 'active',
        last_report_time: 'Mar-10-2021 20:01:59',
        smoking_status: false
      },
      {
        pollution_data: {
          co2: 446,
          pm4_mc: 3.194,
          pm4_nc: 24.114,
          pm0p5_nc: 20.861,
          voc: 0,
          pm1_nc: 24.028,
          pm1_mc: 3.021,
          temperature: 25.4,
          humidity: 16.36,
          pm10_mc: 3.194,
          pm2p5_nc: 24.108,
          pm2p5_mc: 3.194,
          pm10_nc: 24.118
        },
        timestamp: 1616365682,
        vin: '94739746',
        device_id: 'NS20470028',
        time: '2021-03-21T22:28:02.000Z',
        aqi_data: {
          co2_aqi: 23,
          dominant_pollutant: 'co2',
          aqi: 23,
          voc_aqi: 0,
          pollution_category: 'good',
          pm25_aqi: 5,
          pm10_aqi: 3
        },
        smoking_detected: false,
        status: 'active',
        last_report_time: 'Mar-22-2021 0:28:02',
        smoking_status: false
      }
    ],
    devices_id: ['NS20470028', 'NS20470047', 'NS20470006', 'NS20470007', 'NS20470002']
  },
  getters: {
    getDevicesData: state => {
      if (state.devices.length > 0) return state.devices
      return []
    }
  },
  mutations: {
    pushDevice(state, device_data) {
      state.devices.push(device_data)
    },
    updateDevice(state, payload) {
      Vue.set(state.devices, payload.i, payload.message)
      // state.devices[payload.i] = payload.message;
    },
    pushDeviceId(state, device_id) {
      state.devices_id.push(device_id)
    },
    clearDevices(state) {
      state.devices = []
      state.devices_id = []
    }
  },
  actions: {
    wsPushDevice(context, device_data) {
      context.commit('pushDevice', device_data)
    },
    wsUpdateDevice(context, payload) {
      context.commit('updateDevice', payload)
    },
    wsPushDeviceId(context, device_id) {
      context.commit('pushDeviceId', device_id)
    },
    clearDevices(context) {
      context.commit('clearDevices')
    },
    async fetchDeviceData(context, payload) {
      if (context.rootGetters['auth/getUserTokens']) {
        const url = `${restConfig.ingestEndpoint}/devices/`
        const jwt = context.rootGetters['auth/getUserJwt']
        const config = {
          headers: {
            Authorization: jwt
          }
        }
        await axios
          .get(url, config)
          .then(res => {
            res.data.forEach(device => {
              context.commit('pushDeviceId', device.device_id)
              let endpoint = url + device.device_id
              // console.log(endpoint)
              axios
                .get(endpoint, config)
                .then(res => {
                  let data
                  if (res.data) {
                    data = res.data
                    data.status = getStatus(data.timestamp)
                    data.last_report_time = timestampToDate(data.timestamp)
                    data.smoking_status = getSmokingStatus(data.smoking_score)
                  } else {
                    data = {}
                    data.timestamp = 0
                    data.smoking_score = 0
                    data.status = getStatus(data.timestamp)
                    data.last_report_time = timestampToDate(data.timestamp)
                    data.smoking_status = getSmokingStatus(data.smoking_score)
                  }
                  context.commit('pushDevice', data)
                })
                .catch(error => console.error(error))
            })
          })
          .catch(err => console.error('err: ' + err))
      }
    }
  }
}
