<template>
  <div>
    <v-card max-width="400" class="mb-5">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Create a new account</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear :indeterminate="true" v-show="request"></v-progress-linear>
      <v-card-text>
        <ValidationObserver ref="observer" v-slot="{ validate, reset }">
        <v-form ref="form" max-width="200">
          <ValidationProvider
            v-slot="{errors}"
            rules="required|email"
            v-model="email"
            label="E-mail"
            name="Email"
          >
            <v-text-field
              prepend-icon="mdi-email"
              v-model="email"
              label="E-mail"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{errors}"
            rules="required|min:8"
            v-model="password"
            label="Password"
            name="Password"
          >
            <v-text-field
              prepend-icon="mdi-lock"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              hint="At least 8 characters"
              v-model="password"
              label="Password"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              required
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <!-- TODO: consider adding validation for this from table of existing customer orgs (extend oneOf rule) -->
          <ValidationProvider v-slot="{ errors }" name="Organization" rules="required">
          <v-select
          prepend-icon="mdi-domain"
          v-model="organization"
          :items="orgs"
          :error-messages="errors"
          label="Organization"
          data-vv-name="organization"
          data-vv-rules="required"
          required>
          </v-select>
          </ValidationProvider>
          <v-card-actions>
            <v-btn text align="left" color="primary" @click="signIn">SIGN IN</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="createUserAccount">SIGN UP</v-btn>
          </v-card-actions>
        </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
    <v-snackbar color="error" top v-model="alert">
      {{errorMessage}}
      <v-btn color="black" text @click="alert = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
import { ValidationProvider, ValidationObserver, setInteractionMode  } from "vee-validate";
setInteractionMode('eager');
export default {
  name: "sign-up",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      email: "",
      password: "",
      organization: "",
      request: false,
      showPass: false,
      alert: false,
      errorMessage: "",
      orgs: [
        'Crispify',
        'Zipcar',
        'Avis IL',
        'Avis'
      ],
      org_codes: {
        'Crispify': '00',
        'Zipcar': '01',
        'Avis IL': '02',
        'Avis': '03'
      }
    };
  },
  methods: {
    async createUserAccount() {
      this.$refs.observer.validate();
      this.request = true;
      let org_code = this.org_codes[this.organization];
      Auth.signUp({
        username: this.email,
        password: this.password,
        attributes: {
          email: this.email, // optional
          "custom:organization": this.organization, // other custom attributes
          "custom:organization_code": org_code
        },
        validationData: [] //optional
      })
        .then(data => {
          this.request = false;
          console.log(data);
          AmplifyEventBus.$emit("localUser", data.user);
          if (data.userConfirmed === false) {
            return AmplifyEventBus.$emit("authState", "confirmSignUp");
          }
          return AmplifyEventBus.$emit("authState", "signIn");
        })
        .catch(e => {
          console.log(e);
          if (e.code === "UsernameExistsException") {
            this.errorMessage =
              "User exists but unconfirmed. Resent confiramtion code";
            this.alert = true;
            Auth.resendSignUp(this.email)
              .then(() => {
                console.log("code resent successfully");
                return AmplifyEventBus.$emit("authState", "confirmSignUp");
              })
              .catch(e => {
                console.log(e);
                this.errorMessage = e.message;
                this.alert = true;
              });
          }
        });
      this.request = false;
    },
    signIn() {
      AmplifyEventBus.$emit("authState", "signIn");
    }
  }
};
</script>

<style></style>
