<template>
  <div v-if="totalDevices > 0">
    <v-card outlined flat align="center" height="235">
      <v-icon large class="mt-5 mb-1">mdi-developer-board</v-icon>
      <v-card-title class="justify-center pt-1 display-2 font-weight-black">{{ totalDevices }}</v-card-title>
      <v-card-subtitle class="justify-center">Total Devices</v-card-subtitle>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-card flat>
            <span class="caption">Active:</span>
            <span class="subtitle-2">{{ totalActive }}</span>
            <vue-svg-progress
              :value="calcProgressValue('active')"
              type="rect"
              :options="{
                rectRadius: 5,
                text: '',
                rectHeight: '10',
                rectWidth: '150',
                pathColors: ['#EEE', '#3cd1c2'],
              }"
            ></vue-svg-progress>
          </v-card>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col>
          <v-card flat class="pb-2">
            <span class="caption">Idle:</span>
            <span class="subtitle-2 align-right">{{ totalIdle }}</span>
            <vue-svg-progress
              :value="calcProgressValue('idle')"
              type="rect"
              :options="{
                rectRadius: 5,
                text: '',
                rectHeight: '10',
                rectWidth: '150',
                pathColors: ['#EEE', '#ffaa2c'],
              }"
            ></vue-svg-progress>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
  <v-skeleton-loader type="card" v-else></v-skeleton-loader>
</template>

<script>
import { mapGetters } from 'vuex'
import svg from 'svg-progress-bar'

export default {
  components: {
    'vue-svg-progress': svg,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('devices', {
      devicesData: 'getDevicesData',
    }),
    totalDevices() {
      return 45
    },
    totalActive() {
      return 19
    },
    totalIdle() {
      return this.totalDevices - this.totalActive
    },
  },
  methods: {
    calcProgressValue(status) {
      if (status === 'idle') {
        return (this.totalIdle / this.totalDevices) * 100
      } else {
        return (this.totalActive / this.totalDevices) * 100
      }
    },
  },
}
</script>

<style>
</style>
