import { getDayAgoTimestamp, getWeekAgoTimestamp } from './utils.js'

function timeBins(range) {
  let numBins
  let getPrevTimestamp
  if (range === 'week') {
    numBins = 7
    getPrevTimestamp = getDayAgoTimestamp
  } else if (range === 'month') {
    numBins = 4
    getPrevTimestamp = getWeekAgoTimestamp
  } else {
    throw new Error('Invalid range provided for tim binning')
  }
  let bins = []
  let ts = new Date()
  ts.setHours(0, 0, 10, 0)
  ts = Math.floor(ts / 1000)

  for (let i = 0; i < numBins; i++) {
    let prev = getPrevTimestamp(ts)
    bins.unshift([prev, ts])
    ts = prev - 1
  }
  return bins
}

export function binSmokingEvents(smokingEvents, range) {
  const bins = timeBins(range)
  let eventsCount = []
  let categories = []
  for (let b of bins) {
    let [start, end] = b
    categories.push(new Date(start * 1000).toLocaleDateString('en-GB'))
    let count = smokingEvents.filter(e => e.event_start > start && e.event_start < end).length
    eventsCount.push(count)
  }
  return {
    data: eventsCount,
    categories: categories
  }
}

export function binSmokingEventsDemo(range) {
  const bins = timeBins(range)
  let eventsCount = []
  if (range === 'week') eventsCount = [6, 8, 2, 4, 1, 0, 2]
  if (range === 'month') eventsCount = [24, 35, 28, 23]
  let categories = []
  for (let b of bins) {
    let [start, end] = b
    categories.push(new Date(start * 1000).toLocaleDateString('en-GB'))
  }
  return {
    data: eventsCount,
    categories: categories
  }
}

export function binSmokingEventsByCar(smokingEvents, range) {
  const bins = timeBins(range)
  let carsCount = []
  let avgEventsPerCar = []
  let categories = []
  for (let b of bins) {
    let [start, end] = b
    categories.push(new Date(start * 1000).toLocaleDateString('en-GB'))
    let binEvents = smokingEvents.filter(e => e.event_start > start && e.event_start < end)
    let binCarsCount = binEvents.map(e => e.vin).filter((v, i, e) => e.indexOf(v) === i).length //length of unique list of vins with events for this bin
    let avgEventsPerCarInBin

    binEvents.length === 0
      ? (avgEventsPerCarInBin = 0)
      : (avgEventsPerCarInBin = binEvents.length / binCarsCount)

    carsCount.push(binCarsCount)
    avgEventsPerCar.push(avgEventsPerCarInBin)
  }
  return {
    carsCount,
    avgEventsPerCar,
    categories: categories
  }
}

export function binSmokingEventsByCarDemo(smokingEvents, range) {
  const bins = timeBins(range)
  let [carsCount, eventsCount] = [[], []]
  if (range === 'week') {
    eventsCount = [6, 8, 2, 4, 1, 0, 2]
    carsCount = [4, 4, 2, 2, 1, 0, 2]
  }
  if (range === 'month') {
    eventsCount = [24, 35, 28, 23]
    carsCount = [10, 7, 5, 7]
  }
  let avgEventsPerCar = eventsCount.map((e, i) => {
    return e === 0 ? 0 : +(e / carsCount[i]).toFixed(1)
  })
  let categories = []
  for (let b of bins) {
    let [start, end] = b
    categories.push(new Date(start * 1000).toLocaleDateString('en-GB'))
  }
  return {
    carsCount,
    avgEventsPerCar,
    categories: categories
  }
}

export function eventsByCar(data) {
  let result = []
  data.forEach(e => {
    const deviceId = e.device_id
    const vin = e.vin
    const org = e.org
    const idx = result.findIndex(obj => obj.deviceId === deviceId)
    if (idx !== -1) result[idx].smoking_events_count++
    else
      result.push({
        deviceId,
        vin,
        org,
        smoking_events_count: 1
      })
  })
  return result
}
