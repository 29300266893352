<template>
  <div class="mt-4">
    <v-menu
      bottom
      offset-y
      transition="slide-y-transition"
      :disabled="disabled"
      max-width="400"
      max-height="600"
      z-index="15"
    >
      <template v-slot:activator="{ on }">
        <v-btn text depressed v-on="on">
          <v-icon color="grey darken-2">mdi-bell</v-icon>
          <v-badge
            v-if="counter > 0"
            bordered
            overlap
            right
            offset-y="1"
            :content="counter"
            color="primary"
          ></v-badge>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-content>
          <v-list-item
            two-line
            v-for="(notification, id) in notifications"
            :key="id"
            @click="changeNotificationStatus(notification)"
          >
            <v-list-item-action>
              <v-btn icon>
                <v-icon :color="getIconColor(notification.read)" class="pl-0">mdi-email</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="pr-0">{{ notification.message }}</v-list-item-title>
              <v-list-item-subtitle class="pr-0">{{
                getTime(notification.event_start)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-content>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { timestampToDate } from '@/helpers/utils'
export default {
  name: 'notification-dropdown',
  data() {
    return {
      latestcount: 10,
    }
  },
  computed: {
    notifications() {
      const notifications = this.$store.getters['notifications/getLatestNotifications']
      return notifications.length > 0 ? notifications.filter((notification) => !notification.read) : []
    },
    counter() {
      return this.notifications.length
    },
    disabled() {
      if (this.counter > 0) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    getIconColor(read) {
      if (read === true) return 'grey'
      else return 'error'
    },
    changeNotificationStatus(notification) {
      this.$store.dispatch('notifications/changeNotificationStatus', notification)
    },
    getTime(timestamp) {
      return timestampToDate(timestamp)
    },
  },
}
</script>

<style></style>
