<template>
  <div>
    <v-card class="mx-auto" flat outlined max-width="50%">
      <v-container>
        <v-row justify="center">
          <v-col cols="5">
            <v-card-title class="primary--text font-weight-bold">Sales Support</v-card-title>
            <v-card-subtitle class="mt-0">
              <v-row class="ml-0">
                <v-icon small class="mr-2">mdi-phone</v-icon>
                {{ salesSupportNumber }}
              </v-row>
              <v-row class="mt-3 ml-0">
                <v-icon small class="mr-2">mdi-email</v-icon>
                {{ salesSupportMail }}
              </v-row>
            </v-card-subtitle>
          </v-col>
          <v-col cols="5">
            <v-card-title class="primary--text font-weight-bold">Technical Support</v-card-title>
            <v-card-subtitle class="mt-0">
              <v-row class="ml-0">
                <v-icon small class="mr-2">mdi-phone</v-icon>
                {{ techSupportNumber }}
              </v-row>
              <v-row class="mt-3 ml-0">
                <v-icon small class="mr-2">mdi-email</v-icon>
                {{ techSupportMail }}
              </v-row>
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'contacts-card',
  data() {
    return {
      techSupportNumber: '+972 (54) 5631050',
      salesSupportNumber: '+ 972 (52) 7041100',
      techSupportMail: 'support@crispify.io',
      salesSupportMail: 'sales@crispify.io',
      primiumSupportNumber: '+972 (54) 5558888',
      primiumSupportMail: 'info@crispify.io',
    }
  },
}
</script>

<style scoped>
.premium {
  filter: blur(5px);
}
</style>