<template>
  <div>
    <v-card flat>
      <v-row row wrap justify="start" :class="getStyle(this.category)">
        <v-col cols="4">
          <div class="caption grey--text pl-3">Vehicle ID</div>
          <div class="pl-3">{{ this.data.device }}</div>
        </v-col>
        <v-col cols="4">
          <div class="caption grey--text">Infection Risk Level</div>
          <div>{{ this.data.infection_risk }}</div>
        </v-col>
        <v-col cols="4">
          <div class="caption grey--text">Infection Risk Category</div>
          <div>{{ this.category }}</div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'covid-device-card',
  props: ['data'],
  computed: {
    category: function () {
      if (this.data.infection_risk === 4) return 'High'
      if (this.data.infection_risk === 3) return 'Medium'
      if (this.data.infection_risk === 2) return 'Low'
      return 'Safe'
    },
  },
  methods: {
    getStyle(c) {
      const ret = 'ma-2 '
      if (c === 'High') return ret + 'high'
      if (c === 'Medium') return ret + 'med'
      if (c === 'Low') return ret + 'low'
      return ret + 'safe'
    },
  },
}
</script>

<style>
.high {
  border-left: 4px solid #ff859b;
}
.med {
  border-left: 4px solid #ffe770;
}
.low {
  border-left: 4px solid #6cffcd;
}

.safe {
  border-left: 4px solid #6cc5ff;
}
</style>
