<template>
  <div>
    <v-container>
      <v-row id="main_container">
        <v-col cols="7">
          <v-row>
            <v-col cols="7">
              <v-text-field
                hide-details="auto"
                clearable
                dense
                label="Filter by vehicle ID"
                append-icon="search"
                v-model="deviceFilter"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <device-card
                v-for="device in filteredDevices"
                :key="device.device"
                :data="device"
              ></device-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import deviceCard from '../components/cleanness/deviceCard'
export default {
  name: 'cleanness-view',
  components: {
    apexchart: VueApexCharts,
    'device-card': deviceCard,
  },
  data() {
    return {
      deviceFilter: null,
      chartOptions: {
        labels: ['clean', 'dirty', 'maintenance required'],
        chart: {
          type: 'pie',
        },
        plotOptions: {
          donut: {
            size: '5',
            labels: {
              show: true,

              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter: function (val) {
                  return val
                },
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val
                },
              },
              total: {
                show: false,
                showAlways: false,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                },
              },
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      devices: 'demo/sortedDevicesDemoDataByCleanness',
      series: 'demo/cleannessSeries',
    }),
    filteredDevices: function () {
      let filter
      if (this.deviceFilter === null) filter = '\\d+'
      else filter = this.deviceFilter
      return this.devices.filter((d) => {
        const re = new RegExp(filter)
        return d.device.toString().match(re)
      })
    },
    mounted() {
      this.$store.commit('demo/cleannessSeries')
      return true
    },
  },
}
</script>

<style>
</style>