<template>
  <div v-if="smokingEventsLastMonth">
    <v-card flat outlined>
      <v-card-title class="font-weight-bold">
        Smoking Events Trend
        <v-spacer></v-spacer>
        <v-card-actions justify="right">
          <v-btn small outlined color="primary" :input-value="currentData(4)" @click="setRange(4)"
            >Last Month</v-btn
          >
          <v-btn small outlined color="primary" :input-value="currentData(1)" @click="setRange(1)"
            >Last Week</v-btn
          >
        </v-card-actions>
      </v-card-title>
      <v-card-text>
        <apexchart type="line" :options="updateChartOptions" :series="updateChartSeries" height="350">
        </apexchart>
      </v-card-text>
    </v-card>
  </div>
  <v-skeleton-loader type="card" v-else></v-skeleton-loader>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters, mapActions, mapState } from 'vuex'
import { binSmokingEventsDemo, binSmokingEvents } from '../../helpers/insights.js'
export default {
  name: 'smoking-events-trend',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      range: 1,
      ymax: 50,
      ymin: 0,
      chartOptions: {
        chart: {
          id: 'smoking-trend',
          type: 'line',
          height: 350,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        colors: ['#166864'],
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#166864'],
          },
        },
        xaxis: {
          type: 'categories',
          categories: [],
        },
        yaxis: {
          show: true,
          min: this.ymin,
          max: this.ymax,
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      getUserTokens: 'getUserTokens',
    }),
    ...mapGetters('insights', {
      smokingEventsLastWeek: 'getSmokingEventsLastWeek',
      smokingEventsLastMonth: 'getSmokingEventsLastMonth',
    }),
    binnedEvents() {
      if (this.range === 1) return binSmokingEvents(this.smokingEventsLastWeek, 'week')
      return binSmokingEvents(this.smokingEventsLastMonth, 'month')
    },
    binnedEventsDemo() {
      if (this.range === 1) return binSmokingEventsDemo('week')
      return binSmokingEventsDemo('month')
    },
    updateChartSeries() {
      return [
        {
          name: 'Smoking Events per Week',
          data: this.binnedEventsDemo.data,
        },
      ]
    },
    updateChartOptions() {
      let newOpts = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.binnedEvents.categories,
          },
        },
      }
      // console.log(newOpts)
      return newOpts
    },
  },
  methods: {
    ...mapActions('insights', {
      getSmokingEventsForTrend: 'getSmokingEventsForTrend',
    }),
    currentData(n) {
      return n === this.range ? true : undefined
    },
    setRange(range) {
      if (range === 1) {
        this.range = 1
      } else {
        this.range = 4
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>