import Vue from 'vue'
import Vuex from 'vuex'
import { Auth } from 'aws-amplify'
import { createStore } from 'vuex-extensions'
import createPersistedState from 'vuex-persistedstate'
import devices from './modules/devices'
import auth from './modules/auth'
import websocket from './modules/websocket'
import notifications from './modules/notifications'
import reports from './modules/reports'
import insights from './modules/insights'
import demo from './modules/demo'

Vue.use(Vuex)

const presistStore = createPersistedState({
  // reducer: val => {
  //   if (val.auth.signedIn === false) return {}
  //   return val
  // }
  // rehydrated: () => {
  //   console.log('I am rehydrate callback call!')
  // },
  reducer: val => {
    const modulesToExclude = ['websocket']
    if (val.auth.signedIn === false) return {}
    let filteredVal = {}
    let key = ''
    for (key in val) {
      if (!modulesToExclude.includes(key)) {
        filteredVal[key] = val[key]
      }
    }
    return filteredVal
  }
})

const store = createStore(Vuex.Store, {
  strict: true,
  namespaced: true,
  plugins: [presistStore],
  modules: {
    auth,
    devices,
    websocket,
    notifications,
    reports,
    insights,
    demo
  },
  actions: {
    async fetchInitialData(context) {
      console.log('Fetching initial data dispatched')
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          context.dispatch('devices/fetchDeviceData', {}, { root: true })
          context.dispatch('insights/getSmokingEventsForTrend', {}, { root: true })
          context.dispatch('insights/getSmokingRank', {}, { root: true })
          context.dispatch(
            'notifications/fetchUserNotifications',
            { userId: user.attributes.sub },
            { root: true }
          )
        } else {
          console.warn('user not authenticated')
        }
      } catch (error) {
        console.error('currentAuthenticatedUser Error', error)
      }
    }
  }
})

export default store
