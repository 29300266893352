export function getStatus(ts) {
  const tsThresholdForActive = 300 //TODO: this needs to be moved to an env var
  const tsThreshold24hrs = 86400
  let currTimeStamp = Math.floor(Date.now() / 1000)
  // checking to see if device did not report AQ for over 5 minutes
  if (currTimeStamp - ts > tsThreshold24hrs) {
    return '24hrs Idle'
  } else if (currTimeStamp - ts > tsThresholdForActive) {
    return 'idle'
  } else {
    return 'active'
  }
}

export function timestampToDate(ts) {
  let date = new Date(ts * 1000)
  const months_arr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  let year = date.getFullYear()
  let month = months_arr[date.getMonth()]
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = '0' + date.getMinutes()
  let seconds = '0' + date.getSeconds()
  let convTime =
    month +
    '-' +
    day +
    '-' +
    year +
    ' ' +
    hours +
    ':' +
    minutes.substr(-2) +
    ':' +
    seconds.substr(-2)
  return convTime
}

export function getSmokingStatus(score) {
  let smoking_threshold = 10 // TODO: this shoulde be moved
  if (score > smoking_threshold) {
    return true
  } else {
    return false
  }
}

// Notifications utils
export function genNotification(data) {
  if (data.smoking_status) {
    return {
      id: data.device_id,
      timestamp: data.timestamp,
      time: data.last_report_time,
      content: `Smoking detected for device: ${data.device_id}`,
      read: false
    }
  }
}

export function checkEventNotificationExists(notifications, data) {
  let x = notifications.find(n => n.id === data.id)
  if (x) {
    // if we alreday have a notification for device XX we want to check the timestamp and only add a new one
    // in case time > threshold (to seperate different smoking events)
    if (x.timestamp - data.timestamp < 420) {
      return true
    }
    return false
  }
}

export function dateStrToTimestamp(date) {
  let splitDate = date.split('-')
  let newDate = splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0]
  return new Date(newDate).getTime() / 1000
}

const DAY_IN_SEC = 86400
const WEEK_IN_SEC = 604800
const MONTH_IN_SEC = 2629800

export function getDayAgoTimestamp(ts) {
  return ts - DAY_IN_SEC
}
export function getDayNextTimestamp(ts) {
  return ts + DAY_IN_SEC
}
export function getWeekAgoTimestamp(ts) {
  return ts - WEEK_IN_SEC
}
export function getWeekNextTimestamp(ts) {
  return ts + WEEK_IN_SEC
}
export function getMonthAgoTimestamp(ts) {
  return ts - MONTH_IN_SEC
}
export function getMonthNextTimestamp(ts) {
  return ts + MONTH_IN_SEC
}
