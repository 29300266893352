<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="devices"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :search="search"
      item-key="device_id"
      show-expand
      class="elevation-0"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark>{{ item.status }}</v-chip>
      </template>
      <template v-slot:item.smoking_status="{ item }">
        <v-chip :color="getSmokingColor(item.smoking_status)" dark>{{ item.smoking_status }}</v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <device-pollution-details
          :colspan="12"
          :device_data="getPollutionData(item)"
          :device_smoking_score="getDeviceSmokingScore(item)"
          :device_id="item.device_id"
          :lastMsgTs="item.timestamp"
        ></device-pollution-details>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DevicePollutionDetails from './DevicePollutionDetails'
export default {
  name: 'device-data',
  components: {
    DevicePollutionDetails
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      search: '',
      headers: [
        {
          text: 'Device id',
          align: 'left',
          sortable: false,
          value: 'device_id'
        },
        { text: 'Status', value: 'status' },
        { text: 'Last reported', value: 'last_report_time' },
        { text: 'Latest AQI', value: 'aqi_data.aqi' },
        { text: 'Smoking detected', value: 'smoking_status' },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },
  computed: {
    devices() {
      return this.$store.getters['devices/getDevicesData']
    }
  },
  watch: {
    devices(newVal, oldVal) {},
    deep: true
  },
  methods: {
    getStatusColor(status) {
      if (status === 'idle') return '#D38A54'
      else if (status === '24hrs Idle') return 'red'
      else return '#3cd1c2'
    },
    getSmokingColor(status) {
      if (status === true) return '#DE3E35'
      else return '#209AA5'
    },
    getPollutionData(data) {
      let pollution_data = []
      Object.entries(data.pollution_data).forEach(([k, v]) => {
        let reg = /_nc$/
        if (k.match(reg)) {
          return
        }
        pollution_data.push({ pollutant: k, value: v })
      })
      pollution_data.push
      return pollution_data
    },
    getDeviceSmokingScore(data) {
      return data.smoking_score
    }
  }
}
</script>