<template>
  <v-app>
    <Navbar></Navbar>
    <v-main class="mx-4">
      <router-view class="fill-height"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { components } from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import Navbar from '@/components/app-navbar/Navbar'

export default {
  name: 'App',
  components: {
    Navbar,
    ...components,
  },
  data: () => ({}),
  computed: {
    wsConnected() {
      return this.$store.getters['websocket/getSocketIsConnected']
    },
    wsOnMessage() {
      return this.$store.getters['websocket/getSocketMessage']
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
  },
  watch: {
    wsConnected(newVal, oldVal) {
      console.log(`-i- WS connection status change from ${oldVal} to ${newVal}`)
      if (newVal) {
        const user_id = this.$store.getters['auth/getUserId']
        const org = this.$store.getters['auth/getUserOrgCode']
        const mail = this.$store.getters['auth/getUserEmail']
        this.$store.dispatch(
          'websocket/sendMessage',
          JSON.stringify({ message: 'setUser', user: { user_id, mail, org } })
        )
      } else {
        // WS reconnect here
      }
    },
    wsOnMessage(newVal, oldVal) {
      // console.log('WS message recieved: ' + JSON.stringify(newVal))
      if (Object.prototype.hasOwnProperty.call(newVal, 'aqi_data')) {
        this.$store.dispatch('websocket/updateDevicesFromWs', newVal)
      }
    },
  },
}
</script>

