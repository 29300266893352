<template>
 <device-data></device-data>
</template>

<script>
import DeviceData from '../components/devices-data/DeviceData'
export default {
  name: 'devices',
  components: {
    DeviceData
  },
  data() {
    return {
      
    }
  },
  methods: {
    sortBy(prop) {
      this.devices.sort((a,b) => a[prop] < b[prop] ? -1 : 1)
    }
  },
  computed: {
    devices() {
      return this.$store.getters['devices/getDevicesData'];
    }
  },
  watch: {
    devices(newVal, oldVal) {
      // console.log('-i- Devices data array updated in store');
      // console.log(oldVal);
      // console.log('------------');
      // console.log(newVal);
    },
    deep: true
  }
}
</script>

<style>
.device.active{
  border-left: 4px solid #3cd1c2;
}
.device.status.active{
  border-left: transparent;
  color: #3cd1c2;
}
.device.idle{
  border-left: 4px solid #ffaa2c;
}
.device.status.idle{
  border-left: transparent;
  color: #ffaa2c;
}
.v-chip.false{
  background: #3cd1c2;
}
.v-chip.true{
  background: #f83e70;
}
.theme--light.v-chip:not(.v-chip--active) {
  background: #2600ff;
}
</style>