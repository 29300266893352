<template>
  <div>
    <v-dialog max-width="80%" transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" class="grey--text">
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-btn>
      </template>
      <template v-slot:default="dialog">
        <v-card color="#f5f5f5">
          <v-toolbar color="primary" dark>Device Air-Quality Data</v-toolbar>
          <div class="pa-10">
            <v-row>
              <v-col cols="12">
                <v-card-subtitle>Particulate Matter</v-card-subtitle>
                <apexchart type="area" height="300" :options="chartOpts" :series="pmSeries"></apexchart>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-card-subtitle>Chemicals</v-card-subtitle>
                <apexchart type="area" height="250" :options="chartOptions" :series="chemSeries"></apexchart>
              </v-col>
              <v-col cols="6">
                <v-card-subtitle>Carbon Dioxide</v-card-subtitle>
                <apexchart type="area" height="250" :options="chartOptions" :series="co2Series"></apexchart>
              </v-col>
            </v-row>
          </div>

          <v-card-actions class="justify-end" @click="dialog.value = false">
            <v-btn text>Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      pop: true,
      seriesLength: 20,
      chartOptions: {
        chart: {
          height: 350,
          width: '70%',
          type: 'area',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
      },
    }
  },
  computed: {
    pmSeries: function () {
      const pm10Series = Array.from(Array(this.seriesLength)).map((x) => Math.floor(Math.random() * 250))
      const pm25Series = pm10Series.map((v) => {
        const factor = Math.floor(Math.random() * 10)
        return v + factor
      })
      return [
        { name: 'Dust', data: pm10Series },
        { name: 'Fine Particles', data: pm25Series },
      ]
    },
    chemSeries: function () {
      const vocSeries = Array.from(Array(this.seriesLength)).map((x) => Math.floor(Math.random() * 150))

      return [{ name: 'Dust', data: vocSeries }]
    },
    co2Series: function () {
      const co2Series = Array.from(Array(this.seriesLength)).map((x) =>
        Math.floor(Math.random() * (700 - 400) + 400)
      )
      return [{ name: 'CO2', data: co2Series }]
    },
    chartOpts: function () {
      const xCategories = this.genCategories()
      const opts = this.chartOptions
      opts.xaxis.categories = xCategories
      return opts
    },
  },
  methods: {
    genCategories: function () {
      console.log('called')
      const now = new Date()
      let categories = [now.toISOString()]
      let next = now.getTime() - 30 * 1000

      for (let i = 1; i < this.seriesLength; i++) {
        let nextIso = new Date(next).toISOString()
        categories.push(nextIso)
        console.log(nextIso)
        next = next - 30 * 1000
      }
      console.log(categories)
      return categories.reverse()
    },
  },
}
</script>

<style>
</style>