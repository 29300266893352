<template>
  <div>
    <v-card class="mx-auto my-3" max-width="50%" flat outlined>
      <v-card-title class="ml-2 primary--text" color="primary">We'd ❤ to hear from you</v-card-title>
      <v-card-text>
        <v-form ref="form" disabled>
          <v-container>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:2"
                  v-model="firstName"
                  label="First Name"
                >
                  <v-text-field
                    outlined
                    v-model="firstName"
                    label="First Name"
                    required
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:2"
                  v-model="lastName"
                  label="Last Name"
                >
                  <v-text-field
                    outlined
                    v-model="lastName"
                    label="Last Name"
                    required
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <ValidationProvider v-slot="{ errors }" rules="required|email" v-model="email" label="E-mail">
              <v-text-field
                outlined
                v-model="email"
                label="E-Mail"
                required
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <v-textarea outlined clearable clear-icon="cancel" label="Message" v-model="message"></v-textarea>
          </v-container>
          <v-btn class="ml-3" color="primary" @click="submit">Send</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="4000" centered>{{ snackbarMessage }}</v-snackbar>
  </div>
</template>

<script>
import axios from 'axios'
import { ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import { restConfig } from '@/configs/restConfig'

export default {
  name: 'contact-form',
  components: {
    ValidationProvider,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      error: '',
      snackbar: false,
      snackbarMessage: '🙏 Thanks for reaching out! but this form is disabled in demo mode ',
    }
  },
  computed: {
    ...mapGetters({ getUserEmail: 'auth/getUserEmail', getUserJwt: 'auth/getUserJwt' }),
  },
  methods: {
    async submit() {
      this.snackbar = true
    },
  },
}
</script>

<style lang="scss" scoped>
</style>