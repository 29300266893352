<template>
  <div v-if="show">
    <v-card outlined flat align="center" height="235">
      <v-icon large class="mt-5 mb-1">mdi-broom</v-icon>
      <v-card-title class="justify-center pt-1 display-2 font-weight-black"></v-card-title>
      <v-card-subtitle class="justify-center">
        Cleanness level alerts
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="gotoCleanness()">search</v-icon>
          </template>
          <span>Investigate</span>
        </v-tooltip>
      </v-card-subtitle>
      <div class="mx-5">
        <v-simple-table dense fixed-header height="100">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Vehicle ID</th>
                <th class="text-left">Cleaness Score</th>
                <th class="text-left">Cleaness Category</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in devices" :key="item.id">
                <td>{{ item.device }}</td>
                <td>{{ item.cleanness_score }}</td>
                <td>Maintenance Required</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
  </div>
  <v-skeleton-loader type="card" v-else></v-skeleton-loader>
</template>

<script>
import router from '@/router'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      show: true,
    }
  },
  computed: {
    ...mapGetters({
      cleannessCount: 'demo/cleannessSeries',
      devices: 'demo/requiresMaintenance',
    }),
  },
  methods: {
    gotoCleanness: function () {
      router.push('/cleanness')
    },
  },
  mounted() {
    this.$store.commit('demo/cleannessSeries')
    return true
  },
}
</script>

<style>
</style>
