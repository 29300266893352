  <template>
  <v-card outlined flat class="mx-auto fill-width" align="center" justify="center" height="235">
    <v-card flat class="ma-5" v-if="fleetAvgAqi > -1">
      <apexchart type="radialBar" :options="chartOptions" :series="series" :height="200"></apexchart>
    </v-card>
    <v-skeleton-loader type="card" v-else></v-skeleton-loader>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'crisp-score',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      fleetAvgAqi: 60,
      fleetAvgAqiCategory: {
        color: 'red',
        category: 'poor',
      },
      series: [60, 65, 52, 58],
      chartOptions: {
        // colors: ['#FF9800', '#64a82a'],
        chart: {
          type: 'radialBar',
          toolbar: {
            show: false,
          },
        },
        labels: ['PM10', 'PM2.5', 'Chemicals', 'CO2'],
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '15px',
                fontFamily: 'Lato',
              },
              value: {
                formatter: function (val) {
                  return parseInt(val)
                },
                color: '#888',
                fontSize: '25px',
                fontFamily: 'Lato',
                show: true,
              },
              total: {
                show: true,
                label: 'Crisp Score',
                color: '#000',
                fontSize: '16px',
                fontFamily: 'Lato',
                formatter: function (w) {
                  return 60
                },
              },
            },
          },
        },
        fill: {
          type: 'solid',
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
}
</script>

<style>
</style>