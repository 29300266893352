import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#166864',
        secondary: '#607d8b',
        accent: '#9c27b0',
        error: '#f44336',
        warning: '#ff9800',
        info: '#03a9f4',
        success: '#8bc34a',
        navbar: '#166864'
      },
      dark: {
        primary: '#166864',
        secondary: '#607d8b',
        accent: '#9c27b0',
        error: '#f44336',
        warning: '#ff9800',
        info: '#03a9f4',
        success: '#8bc34a',
        background: '#393A3C',
        navbar: '#272727'
      }
    }
  }
})
