<template>
  <nav v-if="isSignedIn">
    <v-app-bar clipped-left depressed app>
      <v-app-bar-nav-icon v-if="userEmail" class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">Crispify</span>
        <span>Dashboard</span>
        <!-- <span class="font-weight-light red--text darken-4">Demo</span> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <div class="userDetails">
          <h5 class="userEmail">{{ userEmail }}</h5>
          <h5 class="userOrg">{{ userOrg }}</h5>
        </div>
        <notifications-dropdown v-if="isSignedIn"></notifications-dropdown>
        <v-btn text depressed @click="signOut">
          <v-icon center color="grey darken-2">mdi-logout</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isSignedIn"
      clipped
      app
      mini-variant
      expand-on-hover
      v-model="drawer"
      color="navbar"
    >
      <v-list nav>
        <v-list-item two-line v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-action>
            <v-icon class="grey--text text--lighten-1">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title theme="dark" class="grey--text text--lighten-1">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-if="stage === 'dev'">
        <v-list-item v-for="link in debug_links" :key="link.text" router :to="link.route">
          <v-list-item-action>
            <v-icon class="grey--text text--lighten-1">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title theme="dark" class="grey--text text--lighten-1">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import notificationsDropdown from './components/notificationsDropdown'

export default {
  components: {
    'notifications-dropdown': notificationsDropdown,
  },
  data() {
    return {
      stage: this.$STAGE,
      drawer: true,
      links: [
        { icon: 'dashboard', text: 'Dashboard', route: '/' },
        { icon: 'mdi-gauge', text: 'Fleet AQ', route: '/crisp' },
        { icon: 'mdi-virus', text: 'Covid19', route: '/covid' },
        { icon: 'mdi-broom', text: 'Cleanness', route: '/cleanness' },
        { icon: 'mdi-bell', text: 'Notifications', route: '/notifications' },
        { icon: 'mdi-smoking', text: 'Smoking', route: '/reports' },
        { icon: 'email', text: 'Contact', route: '/contact' },
        { icon: 'person', text: 'Account', route: '/account' },
      ],
      debug_links: [
        {
          icon: 'mdi-power-socket',
          text: 'SocketListener',
          route: '/socketListener',
        },
      ],
      signedIn: false,
    }
  },
  computed: {
    userEmail() {
      if (this.signedIn) return this.$store.getters['auth/getUserEmail']
      return ''
    },
    userOrg() {
      if (this.signedIn) return this.$store.getters['auth/getUserOrg']
      return ''
    },
    isSignedIn() {
      return this.$store.getters['auth/isSignedIn']
    },
  },
  watch: {
    isSignedIn(newVal, oldVal) {
      this.signedIn = newVal
    },
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut()
        return AmplifyEventBus.$emit('authState', 'signedOut')
      } catch (error) {
        console.error('error signing out:', error)
      }
    },
  },
}
</script>

<style scoped>
.userDetails {
  display: flex;
  flex-flow: column wrap;
  text-transform: uppercase;
  font-weight: bold;
  color: #1f1f1f;
  justify-content: center;
}

.userEmail {
  align-self: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  align-self: center;
}

.userOrg {
  align-self: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  align-self: center;
}
</style>