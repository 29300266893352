<template>
  <div>
    <contacts-card></contacts-card>
    <contact-form></contact-form>
  </div>
</template>

<script>
import ContactsCard from '../components/contact/ContactsCard'
import ContactForm from '../components/contact/ContactForm'
export default {
  name: 'contact',
  components: {
    'contacts-card': ContactsCard,
    'contact-form': ContactForm,
  },
}
</script>

<style lang="scss" scoped>
</style>