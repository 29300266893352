import axios from 'axios'

import { checkEventNotificationExists } from '../../helpers/utils'
import { restConfig } from '@/configs/restConfig.js'

export default {
  namespaced: true,
  state: {
    notifications: [
      {
        event_type: 'smoking',
        created_at: '2021-03-22T04:23:56.000Z',
        read: false,
        vin: '91142450',
        device_id: 'NS20470002',
        message: 'Smoking detected. Vehicle ID: 91142450',
        event_start: 1616387036
      },
      {
        event_type: 'smoking',
        org: '01',
        created_at: '2021-03-21T22:30:41.000Z',
        read: false,
        vin: '91142786',
        device_id: 'NS20470003',
        message: 'Smoking detected. Vehicle ID: 91142786',
        event_start: 1616365841
      },
      {
        event_type: 'cleanness',
        org: '01',
        created_at: '2021-03-21T22:29:41.000Z',
        read: false,
        vin: '91142981',
        device_id: 'NS20470002',
        message: 'Cleaness alert. Vehicle ID: 91142981',
        event_start: 1616365781
      },
      {
        event_type: 'smoking',
        org: '01',
        created_at: '2021-03-18T18:53:07.000Z',
        read: false,
        vin: '91142450',
        device_id: 'NS20470002',
        message: 'Smoking detected. Vehicle ID: 91142450',
        event_start: 1616093587
      },
      {
        event_type: 'infection',
        org: '01',
        created_at: '2021-03-18T18:50:57.000Z',
        read: false,
        vin: '91142544',
        device_id: 'NS20470002',
        message: 'Infection risk level alert. Vehicle ID: 91142544',
        event_start: 1616093457
      },
      {
        event_type: 'smoking',
        org: '01',
        created_at: '2021-03-18T18:33:30.000Z',
        read: false,
        vin: '91142899',
        device_id: 'NS20470002',
        message: 'Smoking detected. Vehicle ID: 91142899',
        event_start: 1616092410
      },
      {
        event_type: 'smoking',
        org: '01',
        created_at: '2021-03-18T18:27:37.000Z',
        read: false,
        vin: '91142450',
        device_id: 'NS20470002',
        message: 'Smoking detected. Vehicle ID: 91142450',
        event_start: 1616092057
      }
    ],
    unreadNotificationsCounter: 0,
    LATEST_COUNT: 10
  },
  getters: {
    getNotifications: state => {
      return state.notifications
    },
    getLatestNotifications: state => {
      const startIndex = 0
      const endIndex = state.notifications.LATEST_COUNT
      return state.notifications.slice(startIndex, endIndex)
    },
    getNotificationsCount: state => {
      return state.unreadNotificationsCounter
    },
    getPopupMaxLength: state => {
      return state.popupMaxLength
    }
  },
  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload
    },
    addNewNotification(state, payload) {
      if (!checkEventNotificationExists(state.notifications, payload)) {
        state.notifications.push(payload)
        // state.unreadNotificationsCounter += 1
      }
    },
    changeNotificationStatusLocal(state, notification) {
      let i = state.notifications.indexOf(notification)
      if (i !== -1) {
        state.notifications[i].read = !notification.read
      }
      // state.unreadNotificationsCounter -= 1
    },
    deleteNotificationLocal(state, notification) {
      let i = state.notifications.indexOf(notification)
      state.notifications.splice(i, 1)
    },
    pushNotification(state, notification) {
      state.notifications.unshift(notification)
    }
  },
  actions: {
    async fetchUserNotifications(context, payload) {
      const { userId } = payload
      if (context.rootGetters['auth/getUserTokens']) {
        const jwt = context.rootGetters['auth/getUserJwt']
        const headers = {
          headers: {
            Authorization: jwt
          }
        }
        const url = `${restConfig.notificationsEndpoint}/notifications/${userId}`
        const response = await axios.get(url, headers)
        context.commit('setNotifications', response.data)
      }
    },
    async changeNotificationStatus(context, payload) {
      context.commit('changeNotificationStatusLocal', payload)
    },
    async deleteNotification(context, payload) {
      context.commit('deleteNotificationLocal', payload)
    }
  },
  async pushNotification(context, payload) {
    const { payload: notification } = payload
    context.commit('pushNotification', notification)
  }
}
