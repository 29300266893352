<template>
  <div>
    <v-card flat>
      <v-row row wrap justify="start" :class="getStyle(this.data.cleanness_score)">
        <v-col cols="4">
          <div class="caption grey--text pl-3">Vehicle ID</div>
          <div class="pl-3">{{ this.data.device }}</div>
        </v-col>
        <v-col cols="4">
          <div class="caption grey--text">Cleanness Score</div>
          <div>{{ this.data.cleanness_score }}</div>
        </v-col>
        <v-col cols="4">
          <div class="caption grey--text">Cleanness Category</div>
          <div>{{ this.category }}</div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'covid-device-card',
  props: ['data'],
  computed: {
    category: function () {
      if (this.data.cleanness_score === 3) return 'Maintenance Required'
      if (this.data.cleanness_score === 2) return 'Dirty'
      return 'Clean'
    },
  },
  methods: {
    getStyle(c) {
      const ret = 'ma-2 '
      if (c === 3) return ret + 'maintenance'
      if (c === 2) return ret + 'dirty'
      return ret + 'clean'
    },
  },
}
</script>

<style>
.maintenance {
  border-left: 4px solid #ffe770;
}
.dirty {
  border-left: 4px solid #6cffcd;
}

.clean {
  border-left: 4px solid #6cc5ff;
}
</style>
