<template>
  <div>
    <h1 class="display-2 text-center ma-5 header">Oops, Seems like something's missing here...</h1>
    <h1 class="display-3 text-center ma-5 e404">404 - Not Found</h1>
    <v-row align="center" justify="center" class="ma-5">
      <v-img
        contain="true"
        justify="center"
        align="center"
        src="../assets/crispifyLogoTransparent_Small2.png"
        max-width="300"
      ></v-img>
    </v-row>
    <v-row align="center" justify="center" class="ma-5">
      <router-link style="text-decoration: none" :to="{name: 'dashboard'}">
        <v-icon color="primary" large>mdi-home</v-icon>
      </router-link>
    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.header {
  color: #166864 !important;
}
.e404 {
  color: #ff5252 !important;
  font-weight: bold;
}
</style>