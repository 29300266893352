import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'
import store from '../store/store'
import Dashboard from '../views/Dashboard.vue'
import Account from '../views/Account.vue'
import Login from '../views/Login.vue'
import Contact from '../views/Contact.vue'
import Devices from '../views/Devices.vue'
import SocketListener from '../views/ListenToSocket.vue'
import Notifications from '../views/Notifications.vue'
import Reports from '../views/Reports.vue'
// import Ride from '../views/Ride.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'
import Covid from '../views/Covid.vue'
import Cleanness from '../views/Cleanness.vue'
import Crisp from '../views/Crisp.vue'
import { sessionIsValid } from '../helpers/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { requiresAuth: true }
  },
  {
    path: '/socketListener',
    name: 'socketListener',
    component: SocketListener,
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: { requiresAuth: true }
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: { requiresAuth: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { requiresAuth: true }
  },
  {
    path: '/crisp',
    name: 'crisp',
    component: Crisp,
    meta: { requiresAuth: true }
  },
  {
    path: '/covid',
    name: 'covid',
    component: Covid,
    meta: { requiresAuth: true }
  },
  {
    path: '/cleanness',
    name: 'cleanness',
    component: Cleanness,
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFoundComponent
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isSignedIn'] && sessionIsValid(store.getters['auth/getUserTokenExpiryTime'])) {
      if (!store.getters['websocket/getSocketIsConnected']) {
        // console.info('user logged in but ws is not connected - reconnecting...')
        // Vue.prototype.$connect()
      }
      next()
    } else {
      // if we got here the use is either signedout or session has expired
      store.dispatch('auth/setSignedIn', false)
      next('/login')
    }
  }
  next()
})

export default router
