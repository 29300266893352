<template>
  <div>
    <v-card flat outlined class="mx-auto fill-width" align="center" justify="center" height="235">
      <v-card-title class="pb-10">{{ this.title }}</v-card-title>
      <div>
        <apexchart type="radialBar" :options="chartOptions" :series="[this.pollutantValue]"></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    pollutantName: {
      type: String,
      required: true,
    },
    pollutantValue: {
      type: String,
      requitred: true,
    },
    category: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'radialBar',
          offsetY: -40,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '97%',
              margin: 20, // margin is in pixels
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                offsetY: -40,
                fontSize: '22px',
                fontFamily: 'Lato',
                formatter: (val) => {
                  return val
                },
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: 'solid',
          colors: ['#FF9800'],
        },
        labels: [this.category],
      },
    }
  },
}
</script>

<style>
</style>